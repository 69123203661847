<template>
  <div class="instruction">
    <h2
      class="instruction-title subtitle"
    >
      How to Use the Muttnik Random Generator
    </h2>
    <ol>
      <li>Press <strong>Connect</strong> to connect your ETH Wallet.</li>
      <li>Press <strong>Generate</strong> as many times as you'd like until you find the Muttnik you love.</li>
      <li>Press <strong>Mint</strong> and sign the transaction to collect your Muttnik.</li>
      <li>Generate and mint as many as you like (No cost to use the generator.)</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "generator-instructions"
};
</script>

<style scoped lang='scss'>

@import "../styles/vars";
@import "../styles/mixins";

.instruction {
  position: relative;
  max-width: var(--max-width-l);
  margin: auto;
  padding: var(--v-space) var(--gutter);

  .instruction-title {
    @include base-font(h3);
  }
}
</style>