<template>
  <section class="sec-fun-facts">
    <h2 class="subtitle">Fun Facts</h2>
    <div class="cols">
      <div class="col">
        <p>
          November 3rd, 2021 will be the 64th anniversary of Laika’s Sputnik 2 launch.
          The Muttniks Team will be speaking at <a
          target="_blank"
          rel="noopener"
          class="link"
          href="NFT.NYC"
          >NFT.NYC</a> come say hello!
        </p>
        <div class="bullets">
          <i class="top"></i>
          <i class="bottom"></i>
        </div>
      </div>

      <div class="col">
        <p>
            The Laika Muttnik secondary market is available on
            <a
              href="https://opensea.io/collection/muttniks"
              target="_blank"
              rel="noopener"
              class="link"
            >
              OpenSea.
            </a>
        </p>
        <div class="bullets">
          <i class="top"></i>
          <i class="bottom"></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'sec-fun-facts'
};
</script>

<style lang='scss'>
@import '../styles/vars';
@import '../styles/mixins';

.sec-fun-facts {
  padding: var(--v-space) var(--gutter);
  position: relative;
  max-width: var(--max-width-l);
  margin: auto;

  h2 {
    @include base-font(h1);
    font-family: $font-computer;
  }
  .cols {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr;
    grid-gap: calc(var(--gutter)/2);
    align-items: stretch;
    @include media(3-up) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .col {
    position: relative;
    padding: 60px 24px;
    align-items: flex-start;
    @include media(3-up) {
      padding: 64px;
    }
  }
  p {
    margin: 0;
    color: currentColor;
  }
  .col:nth-child(1) {
    background: $color-beige;
    color: $color-green;
  }
  .col:nth-child(2) {
    background: $color-blue;
    color: $color-green;
  }
}
</style>