<template>
  <main class="page terms-of-serivice">
    <h1>Terms of Service</h1>
    <p>
      Welcome to the Cosmic Paws and Muttniks websites, 
      owned and operated by Subtractive, Inc. d/b/a Cosmic 
      Paws and d/b/a Muttniks (“Subtractive,” “Cosmic Paws,”
      “Muttniks,” “we,” “us” or “our”). These Terms of Use 
      (these “Terms”) govern your access to and use of our 
      Cosmic Paws and Muttniks websites; including without 
      limitation the creation, purchase, sale, exchange, or 
      modification of certain digital assets; our online 
      and/or mobile services, and software provided on or in 
      connection with those services (collectively, the “Service”).
    </p>
    <p>
      Cosmic Paws and Muttniks are collections of digital artworks 
      (NFTs) running on the Ethereum network.
    </p>
    <p>
      This website is only an interface allowing participants to 
      exchange digital collectibles. Users are entirely responsible 
      for the safety and management of their own private Ethereum 
      wallets and validating all transactions and contracts generated 
      by this website before approval.
    </p>
    <p>
      Furthermore, as Cosmic Paws and Muttniks run on the Ethereum 
      network, there is no ability to undo, reverse, or restore any 
      transactions.
    </p>
    <p>
      This website and its connected services are provided “as is” 
      and “as available” without warranty of any kind. By using 
      this website you are accepting sole responsibility for any and 
      all transactions involving Cosmic Paws and Muttniks products, 
      website experiences, and digital collectibles.
    </p>
    <h1>
      Ownership
    </h1>
    <ol type="i">
      <li>You Own the NFT. Each Cosmic Paws and Muttniks is an NFT 
        on the Ethereum blockchain. When you purchase an NFT, you 
        own the underlying Cosmic Paws and Muttniks, the Art, completely. 
        Ownership of the NFT is mediated entirely by the Smart Contract 
        and the Ethereum Network: at no point may we seize, freeze, or 
        otherwise modify the ownership of any Cosmic Paws or Muttniks NFT.</li>
      <li>Personal Use. Subject to your continued compliance with these Terms, 
        Subtractive, Inc. grants you a worldwide, royalty-free license to use, 
        copy, and display the purchased Art, along with any extensions that you 
        choose to create or use, solely for the following purposes: 
        <ol>
          <li>For your own personal, non-commercial use;</li>
          <li> As part of a marketplace that permits the purchase 
          and sale of your Muttniks / NFT, provided that the 
          marketplace cryptographically verifies each Cosmic Paws 
          or Muttniks owner’s rights to display the Art for their 
          Cosmic Paws or Muttniks to ensure that only the actual 
          owner can display the Art;</li>
          <li> As part of a third party website or application that 
            permits the inclusion, involvement, or participation of 
            your Cosmic Paws or Muttniks, provided that the website/application 
            cryptographically verifies each Cosmic Paws or Muttniks owner’s 
            rights to display the Art for their Cosmic Paws or Muttniks to 
            ensure that only the actual owner can display the Art, and 
            provided that the Art is no longer visible once the owner of 
            the Cosmic Paws or Muttniks leaves the website/application.
            </li> 
          </ol>
        </li>
    </ol>
    </main>
</template>

<script>
export default {
  name: 'terms-of-service'
}
</script>

<style lang='scss' scoped>
@import '../styles/vars';
@import '../styles/mixins';

.sec-frame {
  border-top: 1px solid $color-green4;
}

</style>