<template>
  <section
    class="sec-countdown"
    v-show='counting'
  >
    <h2>Muttniks Generator Launches</h2>
    <p class="type-tagline">JULY 22nd 2021 <br>@ 1:30 AM UTC</p>

    <VueCountdown
      :time="time"
      :interval="100"
      :transform="transformSlotProps"
      v-slot="{ days, hours, minutes, seconds }"
      @end="onCountdownEnd"
      class="time-countdown"
    >
      <span class="time-set">
        <span
          v-for='number in days.split(``)'
          :key="`day-${number}`"
          class="time-value"
        >
          {{number}}
        </span>
        <span class="time-label">Days</span>
      </span>
      :
      <span class="time-set">
        <span
          v-for='number in hours.split(``)'
          :key="`hours-${number}`"
          class="time-value"
        >
          {{number}}
        </span>
        <span class="time-label">Hours</span>
      </span>
      :
      <span class="time-set">
        <span
          v-for='number in minutes.split(``)'
          :key="`minutes-${number}`"
          class="time-value"
        >
          {{number}}
        </span>
        <span class="time-label">Minutes</span>
      </span>
      :
      <span class="time-set">
        <span
          v-for='number in seconds.split(``)'
          :key="`seconds-${number}`"
          class="time-value"
        >
          {{number}}
        </span>
        <span class="time-label">Seconds</span>
      </span>

    </VueCountdown>
  </section>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'sec-countdown',
  components: {
    VueCountdown
  },
  data() {
    const now = new Date();
    // Date.UTC(year, month, day, hour, minute, second, millisecond)
    // const expireDate = new Date(Date.UTC('July 22, 2021 01:30:00'));
    const expireDate = new Date(Date.UTC(2021, 6, 22, 1, 30, 0, 0));
    const expires = expireDate - now;
    const time = expires < 0 ? 0 : expires;
    return {
      time,
      counting: true
    };
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });
      return formattedProps;
    },
    onCountdownEnd() {
      this.counting = false;
    },
  }
};
</script>

<style lang='scss' scoped>
@import '../styles/vars';
@import '../styles/mixins';

.sec-countdown {
  position: relative;
  padding: 80px 188px 0;

  @include bp(tablet) {
    padding: 40px 60px;
  }
  @include bp(mobile) {
    padding: 30px;
  }
}

h2 {
  @include base-font(h1);
  color: $color-green;
  margin-bottom: 10px;
}

.type-tagline {
  @include bp(mobile) {
    margin-bottom: 15px;
  }
}

.type-tagline br {
  display: none;
  @include bp(mobile) {
    display: block;
  }
}

.time-countdown {
  display: flex;
  @include fluid-type(72px, 92px);
  @include bp(tablet) {
    @include fluid-type(24px, 48px);
  }
  @include bp(tablet) {
    @include fluid-type(18px, 30px);
  }
}

.time-set {
  --time-w: 96px;
  --time-h: 140px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, var(--time-w));
  grid-gap: 10px;
  @include bp(tablet) {
    --time-w: 48px;
    --time-h: 70px;
  }
  @include bp(mobile) {
    --time-w: 30px;
    --time-h: 42px;
    grid-gap: 5px;
  }
}

.time-label {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3em;
  display: block;
  grid-column: span 2;
  @include base-font(small);
  @include bp(mobile) {
    font-size: 10px;
  }
}

.time-value {
  width: var(--time-w);
  height: var(--time-h);
  border: 1px solid $color-green4;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-computer;
}
</style>