<template>
  <section class="sec-hero">
    <figure class="image">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1898 1297">
        <image width="1898" height="1297" xlink:href="@/assets/image/hero.jpg"></image>
        <g
          class="nav-link"
          id="nav-mission"
        >
          <path d="M624.1,29.2l3.2,24.1c0.2,1.2-0.2,1.8-1.1,1.8c-0.8,0-1.3-0.6-1.5-1.8l-3.5-26.5c0-0.3-0.1-0.6,0-0.8 c0-0.7,0.5-1,1.3-1l2.1,0c0.7,0,1.2,0.3,1.6,1l3.3,6.2c0.3,0.6,0.6,0.9,0.8,0.9c0.2,0,0.4-0.3,0.6-0.9l2.3-6.3 c0.3-0.7,0.8-1.1,1.7-1.1l4.6-0.1c0.3,0,0.5,0,0.7,0.1c0.6,0.1,0.9,0.7,1.1,1.7l3.5,26.8c0.1,0.8,0.1,1.3-0.1,1.5 c-0.2,0.2-0.6,0.3-1.2,0.3h-2.5c-0.7,0-1.1-0.1-1.3-0.3c-0.2-0.2-0.3-0.7-0.5-1.5l-3.1-24.2c-0.1-0.6-0.2-0.9-0.4-0.9 c-0.2,0-0.4,0.3-0.6,0.9l-2.8,8.1c-0.3,0.9-0.8,1.4-1.3,1.4c-0.5,0-1-0.4-1.6-1.3l-4.2-8c-0.2-0.5-0.5-0.7-0.6-0.7 C624.2,28.5,624.1,28.8,624.1,29.2z M634.6,55.1h-2.5c-0.6,0-1.1-0.1-1.3-0.3c-0.2-0.2-0.4-0.7-0.5-1.4l-0.8-5.9 c-0.1-0.8-0.1-1.2,0.1-1.5c0.2-0.2,0.6-0.3,1.2-0.3l2.5,0c0.6,0,1.1,0.1,1.3,0.3c0.2,0.2,0.4,0.7,0.5,1.5l0.8,5.9 c0.1,0.7,0.1,1.2-0.1,1.4C635.7,55,635.3,55.1,634.6,55.1z"/>
          <path d="M652.3,55.1h-2.5c-0.7,0-1.1-0.1-1.3-0.3c-0.2-0.2-0.3-0.7-0.5-1.5l-1.4-11c-0.1-0.8,0.1-1.3,0.4-1.5 c0,0,0.5-0.1,1.4-0.3c0.6-0.1,0.8-0.7,0.7-1.7l-1.6-12.4c-0.2-1.2,0.2-1.8,1.1-1.8c0.8,0,1.3,0.6,1.5,1.8l3.4,26.9 c0.1,0.8,0.1,1.3-0.1,1.5C653.3,55,652.9,55.1,652.3,55.1z"/>
          <path d="M670.6,55.1h-11.1c-0.6,0-1-0.1-1.2-0.4c-0.3-0.3-0.4-0.7-0.5-1.4l-0.9-6.8c-0.1-0.8-0.1-1.3,0.1-1.5 c0.2-0.2,0.6-0.3,1.2-0.3l2.5,0c0.7,0,1.1,0.1,1.3,0.3c0.2,0.2,0.4,0.7,0.5,1.5l0.5,3.8c0.1,0.8,0.2,1.2,0.5,1.5 c0.2,0.2,0.6,0.3,1.3,0.3l3.1,0c0.7,0,1.1-0.1,1.2-0.3c0.2-0.2,0.2-0.7,0.1-1.5l-1-7.8c-0.1-0.8-0.3-1.3-0.5-1.5 c-0.2-0.2-0.6-0.3-1.3-0.3l-8.7,0.1c-0.6,0-1-0.1-1.3-0.4c-0.2-0.3-0.4-0.7-0.5-1.4l-1.6-12.6c0-0.3-0.1-0.6,0-0.8 c0-0.7,0.5-1,1.3-1l11.3-0.2c0.3,0,0.5,0,0.7,0.1c0.6,0.1,1,0.7,1.1,1.7l0.7,5.7c0.2,1.2-0.2,1.8-1,1.8c-0.9,0-1.4-0.6-1.6-1.8 l-0.3-2.7c-0.1-0.8-0.2-1.2-0.5-1.5c-0.2-0.2-0.6-0.3-1.3-0.3l-6.1,0.1c-0.7,0-1.1,0.1-1.2,0.3c-0.2,0.2-0.2,0.7-0.1,1.5l0.8,6.6 c0.1,0.8,0.3,1.3,0.5,1.5c0.2,0.2,0.6,0.3,1.3,0.3l8.7-0.1c0.3,0,0.5,0,0.7,0.1c0.6,0.1,1,0.7,1.1,1.7l1.7,13.9 c0.1,0.7,0,1.2-0.2,1.4C671.8,54.9,671.3,55.1,670.6,55.1z"/>
          <path d="M688.9,55.1h-11.1c-0.6,0-1-0.1-1.2-0.4c-0.2-0.3-0.4-0.7-0.5-1.5l-0.9-6.9c-0.1-0.8-0.1-1.3,0.1-1.5 c0.2-0.2,0.6-0.3,1.2-0.3l2.5,0c0.7,0,1.1,0.1,1.3,0.3c0.2,0.2,0.4,0.7,0.5,1.5l0.5,3.9c0.1,0.8,0.2,1.3,0.5,1.5 c0.2,0.2,0.6,0.3,1.3,0.3l3.1,0c0.7,0,1.1-0.1,1.2-0.3c0.2-0.2,0.2-0.7,0.1-1.5l-1-7.9c-0.1-0.8-0.2-1.3-0.5-1.5 c-0.2-0.2-0.6-0.3-1.3-0.3l-8.7,0.1c-0.6,0-1-0.1-1.3-0.4c-0.2-0.3-0.4-0.7-0.5-1.4L672.7,26c0-0.3-0.1-0.6,0-0.8 c0-0.7,0.5-1,1.3-1l11.3-0.2c0.3,0,0.5,0,0.7,0.1c0.6,0.1,1,0.7,1.1,1.7l0.7,5.8c0.1,1.2-0.2,1.8-1,1.8c-0.9,0-1.4-0.6-1.6-1.8 l-0.3-2.7c-0.1-0.8-0.2-1.3-0.5-1.5c-0.2-0.2-0.6-0.3-1.3-0.3l-6.1,0.1c-0.7,0-1.1,0.1-1.2,0.3c-0.2,0.2-0.2,0.7-0.1,1.5l0.8,6.6 c0.1,0.8,0.2,1.3,0.5,1.5c0.2,0.2,0.6,0.3,1.3,0.3l8.7-0.1c0.3,0,0.5,0,0.7,0.1c0.6,0.1,1,0.7,1.1,1.7l1.7,14 c0.1,0.7,0,1.2-0.2,1.5C690.1,54.9,689.6,55.1,688.9,55.1z"/>
          <path d="M698.7,55.1h-2.5c-0.7,0-1.1-0.1-1.3-0.3c-0.2-0.2-0.3-0.7-0.4-1.5L693.1,42c-0.1-0.8,0.1-1.3,0.5-1.5 c0,0,0.5-0.1,1.4-0.3c0.6-0.1,0.8-0.7,0.7-1.7l-1.6-12.7c-0.1-1.2,0.2-1.8,1.1-1.8c0.8,0,1.3,0.6,1.5,1.8l3.4,27.5 c0.1,0.8,0.1,1.3-0.1,1.5C699.8,55,699.4,55.1,698.7,55.1z"/>
          <path d="M719.2,55.1h-13.7c-0.6,0-1-0.1-1.3-0.4c-0.2-0.3-0.4-0.7-0.5-1.4l-3.4-27.6c-0.1-1.2,0.3-1.8,1.3-1.9 l13.7-0.2c0.3,0,0.5,0,0.7,0.1c0.6,0.1,0.9,0.7,1.1,1.8l3.3,27.8c0,0.3,0.1,0.6,0,0.8C720.5,54.7,720.1,55.1,719.2,55.1z M703.4,28.7l2.6,21.4c0.1,0.8,0.2,1.3,0.5,1.5c0.2,0.2,0.6,0.3,1.3,0.3l5.5,0c0.6,0,1-0.1,1.2-0.3c0.2-0.2,0.2-0.7,0.1-1.5 l-1.4-11.9c-0.1-1.1,0.2-1.6,1.1-1.6l0.7,0c0.4,0,0.7-0.2,0.8-0.6c0.1-0.2,0-0.7,0-1.4l-0.7-6c-0.1-0.8-0.2-1.3-0.5-1.5 c-0.2-0.2-0.6-0.3-1.3-0.3l-8.5,0.1c-0.6,0-1,0.1-1.2,0.4C703.4,27.4,703.3,27.9,703.4,28.7z"/>
          <path d="M723.6,28.3l3,24.9c0.1,1.2-0.2,1.8-1.1,1.8s-1.4-0.6-1.5-1.8l-3.3-27.9c0-0.3-0.1-0.6,0-0.8 c0-0.7,0.5-1,1.3-1.1l2,0c0.6,0,1.1,0.1,1.4,0.3c0.1,0.1,0.4,0.5,0.9,1.1l9.3,11.7c0.4,0.4,0.7,0.6,0.9,0.6c0.3,0,0.4-0.6,0.3-1.7 l-1.2-10.3c-0.1-1.2,0.2-1.9,1.1-1.9c0.8,0,1.3,0.6,1.5,1.8l3.3,28.1c0.1,1.2-0.2,1.9-1,1.9c-0.9,0-1.4-0.6-1.6-1.9l-0.8-7.1 c-0.2-1.9-0.7-3.3-1.4-4.2l-11.6-14.5c-0.4-0.5-0.7-0.8-1-0.8C723.6,26.6,723.4,27.2,723.6,28.3z M734,55.1h-2.5 c-0.7,0-1.1-0.1-1.3-0.3c-0.2-0.2-0.4-0.7-0.4-1.5l-0.7-6.1c-0.1-0.8-0.1-1.3,0.1-1.5c0.2-0.2,0.6-0.3,1.2-0.3l2.5,0 c0.6,0,1.1,0.1,1.3,0.3c0.2,0.2,0.4,0.7,0.5,1.5l0.7,6.2c0.1,0.8,0.1,1.3-0.1,1.5C735,54.9,734.6,55.1,734,55.1z"/>
        </g>
        <g
          class="nav-link"
          id="nav-milestones"
        >
          <path d="M811.1,23.4l4,27.5c0.2,1.3-0.1,2-0.9,2c-0.7,0-1.2-0.7-1.4-2l-4.4-30.5c-0.1-0.4-0.1-0.7-0.1-0.9 c0-0.8,0.4-1.1,1.2-1.1l1.9,0c0.6,0,1.1,0.4,1.5,1.2l3.2,7.1c0.3,0.7,0.6,1.1,0.8,1.1c0.2,0,0.4-0.3,0.5-1l1.8-7.1 c0.2-0.8,0.7-1.2,1.5-1.2l4.2,0.1c0.2,0,0.4,0,0.6,0.1c0.5,0.2,0.9,0.8,1,1.9l4,30c0.1,0.9,0.1,1.4,0,1.7 c-0.1,0.2-0.5,0.3-1.1,0.3l-2.2,0c-0.6,0-1-0.1-1.2-0.3c-0.2-0.2-0.3-0.8-0.5-1.7l-3.7-27.3c-0.1-0.7-0.2-1-0.4-1 c-0.2,0-0.3,0.3-0.5,1l-2.2,9.1c-0.2,1-0.6,1.5-1.1,1.5c-0.4,0-0.9-0.5-1.5-1.5l-4.2-9.2c-0.2-0.5-0.5-0.8-0.6-0.8 C811.1,22.6,811,22.8,811.1,23.4z M821.7,52.8l-2.2,0c-0.6,0-1-0.1-1.2-0.3c-0.2-0.2-0.4-0.8-0.5-1.6l-0.9-6.7 c-0.1-0.9-0.1-1.4,0-1.7c0.1-0.2,0.5-0.4,1.1-0.4l2.3,0c0.6,0,1,0.1,1.2,0.3c0.2,0.2,0.4,0.8,0.5,1.6l0.9,6.6 c0.1,0.8,0.1,1.4,0,1.6C822.6,52.7,822.3,52.8,821.7,52.8z"/>
          <path d="M837.4,52.5l-2.3,0c-0.6,0-1-0.1-1.2-0.3c-0.2-0.2-0.3-0.8-0.5-1.7l-1.6-12.2c-0.1-0.9,0-1.4,0.4-1.6 c0,0,0.4-0.1,1.2-0.3c0.5-0.1,0.7-0.8,0.6-1.9l-1.8-13.8c-0.2-1.3,0.1-2,1-2c0.8,0,1.2,0.7,1.4,2l3.8,29.7c0.1,0.9,0.1,1.5,0,1.7 C838.3,52.4,838,52.5,837.4,52.5z"/>
          <path d="M854.2,52.2l-10,0.2c-0.5,0-0.9-0.1-1.1-0.4c-0.2-0.3-0.4-0.8-0.5-1.6l-1.5-12.1c-0.1-0.8,0-1.4,0.4-1.6 c0,0,0.4-0.1,1.2-0.3c0.6-0.1,0.8-0.8,0.6-1.9l-1.7-13.6c-0.2-1.3,0.2-2,0.9-1.9c0.8,0,1.3,0.7,1.4,2l3.2,26.2 c0.1,0.8,0.2,1.4,0.4,1.6c0.2,0.2,0.6,0.3,1.2,0.3l5-0.1c0.9,0,1.4,0.5,1.6,1.6C855.5,51.6,855.1,52.1,854.2,52.2z"/>
          <path d="M869.6,51.9l-9.5,0.2c-0.9,0-1.4-0.7-1.6-2.1L855.2,21c0-0.3-0.1-0.6,0-0.8c0-0.7,0.4-1.1,1.2-1.1l9.8,0.1 c0.9,0,1.4,0.6,1.5,1.7c0.1,1.1-0.3,1.6-1.2,1.6l-7.4-0.1c-0.6,0-1,0.1-1.1,0.3c-0.1,0.2-0.2,0.8-0.1,1.6l0.8,7.3 c0.1,0.8,0.2,1.3,0.4,1.6c0.2,0.2,0.6,0.4,1.2,0.4l7.3,0c0.9,0,1.4,0.5,1.5,1.5c0.1,1.1-0.3,1.7-1.2,1.7l-4.6,0 c-0.6,0-0.9,0.1-1.1,0.4c-0.1,0.2-0.2,0.8-0.1,1.6l0.9,8.1c0.1,0.8,0.2,1.3,0.4,1.6c0.2,0.2,0.6,0.3,1.2,0.3l4.5-0.1 c0.9,0,1.4,0.5,1.5,1.5C870.9,51.3,870.5,51.9,869.6,51.9z"/>
          <path d="M885.2,51.6l-9.9,0.2c-0.5,0-0.9-0.1-1.1-0.4c-0.2-0.3-0.4-0.8-0.4-1.5l-0.7-7.3c-0.1-0.9-0.1-1.4,0.1-1.6 c0.1-0.2,0.5-0.3,1.1-0.3l2.3,0c0.6,0,1,0.1,1.2,0.3c0.2,0.2,0.3,0.7,0.4,1.6l0.4,4.1c0.1,0.8,0.2,1.3,0.4,1.5 c0.2,0.2,0.6,0.3,1.1,0.3l2.8,0c0.6,0,1-0.1,1.1-0.4c0.1-0.2,0.2-0.7,0.1-1.5l-0.8-8.2c-0.1-0.8-0.2-1.3-0.4-1.6 c-0.2-0.2-0.6-0.3-1.2-0.3l-7.9,0c-0.5,0-0.9-0.1-1.1-0.4c-0.2-0.3-0.4-0.8-0.4-1.5l-1.4-13.5c0-0.3,0-0.6,0-0.8 c0-0.7,0.5-1.1,1.2-1.1l10.4,0.1c0.2,0,0.4,0,0.6,0.1c0.5,0.2,0.9,0.8,1,1.8l0.5,6c0.1,1.3-0.2,1.9-1,1.9c-0.8,0-1.3-0.6-1.4-1.9 l-0.3-2.8c-0.1-0.8-0.2-1.3-0.4-1.5c-0.2-0.2-0.6-0.4-1.2-0.4l-5.6-0.1c-0.6,0-1,0.1-1.1,0.3c-0.1,0.2-0.2,0.7-0.1,1.6l0.7,7 c0.1,0.8,0.2,1.3,0.4,1.6c0.2,0.2,0.6,0.3,1.2,0.3l7.9,0c0.2,0,0.4,0,0.6,0.1c0.5,0.2,0.9,0.8,1,1.8l1.3,14.5 c0.1,0.7,0,1.2-0.2,1.5C886.2,51.4,885.8,51.6,885.2,51.6z"/>
          <path d="M898.4,51.4l-2.2,0c-0.6,0-1-0.1-1.2-0.3c-0.2-0.2-0.3-0.7-0.4-1.6l-2.1-25c-0.1-0.8-0.2-1.3-0.4-1.5 c-0.2-0.2-0.6-0.3-1.2-0.4l-2.5,0c-0.9,0-1.4-0.5-1.5-1.6c-0.1-1.1,0.3-1.6,1.3-1.6l13,0.2c0.9,0,1.4,0.5,1.5,1.6 c0.1,1-0.3,1.5-1.3,1.5l-2.5,0c-0.6,0-1,0.1-1.1,0.3c-0.2,0.2-0.2,0.7-0.1,1.5l2,24.9c0.1,0.8,0,1.4-0.1,1.6 C899.4,51.3,899,51.4,898.4,51.4z"/>
          <path d="M921.1,51l-12.2,0.2c-0.5,0-0.9-0.1-1.1-0.4c-0.2-0.3-0.3-0.7-0.4-1.5l-2.1-27.8c-0.1-1.2,0.3-1.8,1.3-1.8 l12.6,0.2c0.3,0,0.5,0,0.6,0.1c0.5,0.2,0.8,0.7,0.9,1.8l1.7,27.3c0,0.3,0,0.6,0,0.8C922.3,50.6,921.8,51,921.1,51z M907.9,24.7 l1.6,21.5c0.1,0.8,0.2,1.3,0.4,1.5c0.2,0.2,0.6,0.3,1.1,0.3l4.9-0.1c0.6,0,0.9-0.1,1.1-0.3c0.2-0.2,0.2-0.7,0.2-1.5l-0.8-11.8 c-0.1-1.1,0.3-1.6,1.1-1.6l0.6,0c0.4,0,0.7-0.2,0.8-0.6c0.1-0.2,0.1-0.7,0-1.4l-0.4-5.9c0-0.8-0.2-1.3-0.4-1.5 c-0.2-0.2-0.6-0.3-1.2-0.3l-7.8-0.1c-0.6,0-1,0.1-1.1,0.3C907.9,23.5,907.8,24,907.9,24.7z"/>
          <path d="M926.3,24.8l1.4,24.3c0.1,1.2-0.3,1.8-1.1,1.8c-0.8,0-1.2-0.6-1.3-1.8l-1.6-27.3c0-0.3,0-0.6,0-0.8 c0.1-0.7,0.5-1,1.3-1l1.8,0c0.5,0,1,0.1,1.2,0.4c0.1,0.1,0.4,0.5,0.8,1.1l7.8,11.4c0.3,0.4,0.6,0.6,0.8,0.6c0.3,0,0.4-0.5,0.4-1.6 l-0.5-9.9c-0.1-1.2,0.3-1.8,1.1-1.8c0.8,0,1.2,0.6,1.2,1.8l1.2,26.8c0.1,1.2-0.3,1.8-1,1.8c-0.8,0-1.2-0.6-1.3-1.8l-0.3-6.8 c-0.1-1.8-0.4-3.1-1.1-4.1l-9.7-14.1c-0.3-0.5-0.6-0.8-0.8-0.8C926.3,23.1,926.2,23.7,926.3,24.8z M934.3,50.8l-2.2,0 c-0.6,0-1-0.1-1.1-0.3c-0.2-0.2-0.3-0.7-0.3-1.5l-0.3-6c0-0.8,0-1.3,0.2-1.5c0.2-0.2,0.5-0.3,1.1-0.3l2.3,0c0.6,0,1,0.1,1.1,0.3 c0.2,0.2,0.3,0.7,0.3,1.5l0.3,5.9c0,0.8,0,1.2-0.2,1.5C935.2,50.7,934.8,50.8,934.3,50.8z"/>
          <path d="M955.3,50.5l-9.5,0.1c-0.9,0-1.4-0.6-1.4-2l-1.2-26.5c0-0.3,0-0.6,0-0.8c0.1-0.7,0.5-1,1.3-1l9.8,0.2 c0.9,0,1.4,0.5,1.4,1.5c0,1-0.4,1.5-1.3,1.5l-7.4-0.1c-0.6,0-1,0.1-1.1,0.3c-0.2,0.2-0.2,0.7-0.2,1.4l0.3,6.7 c0,0.7,0.1,1.2,0.3,1.4c0.2,0.2,0.6,0.3,1.2,0.3l7.3,0c0.9,0,1.4,0.5,1.4,1.4c0,1.1-0.4,1.6-1.3,1.6l-4.6,0c-0.6,0-1,0.1-1.1,0.3 c-0.2,0.2-0.2,0.7-0.2,1.4l0.3,7.4c0,0.7,0.1,1.2,0.3,1.4c0.2,0.2,0.6,0.3,1.1,0.3l4.6-0.1c0.9,0,1.3,0.5,1.4,1.4 C956.6,49.9,956.2,50.4,955.3,50.5z"/>
          <path d="M970.9,50.2l-9.9,0.1c-0.5,0-0.9-0.1-1.1-0.4c-0.2-0.3-0.3-0.7-0.3-1.4l-0.2-6.6c0-0.8,0-1.3,0.2-1.5 c0.2-0.2,0.5-0.3,1.1-0.3l2.3,0c0.6,0,1,0.1,1.1,0.3c0.2,0.2,0.3,0.7,0.3,1.5l0.1,3.7c0,0.7,0.1,1.2,0.3,1.4 c0.2,0.2,0.5,0.3,1.1,0.3l2.8,0c0.6,0,1-0.1,1.1-0.3c0.2-0.2,0.2-0.7,0.2-1.4l-0.1-7.4c0-0.7-0.1-1.2-0.3-1.4 c-0.2-0.2-0.5-0.3-1.1-0.3l-7.9,0c-0.5,0-0.9-0.1-1.1-0.4c-0.2-0.2-0.3-0.7-0.3-1.4l-0.4-12.3c0-0.3,0-0.6,0-0.8 c0.1-0.7,0.5-1,1.3-1l10.4,0.2c0.2,0,0.4,0,0.6,0.1c0.5,0.1,0.8,0.7,0.8,1.7l0.1,5.5c0,1.1-0.4,1.7-1.1,1.7 c-0.8,0-1.2-0.6-1.2-1.7l-0.1-2.5c0-0.7-0.1-1.2-0.3-1.4c-0.2-0.2-0.6-0.3-1.2-0.3l-5.6-0.1c-0.6,0-1,0.1-1.1,0.3 c-0.2,0.2-0.2,0.7-0.2,1.4l0.2,6.4c0,0.7,0.1,1.2,0.3,1.4c0.2,0.2,0.6,0.3,1.2,0.3l7.9,0c0.2,0,0.4,0,0.6,0.1 c0.5,0.1,0.8,0.7,0.8,1.7l0.2,13.2c0,0.7-0.1,1.1-0.3,1.4C971.9,50.1,971.5,50.2,970.9,50.2z"/>
        </g>
        <g
          class="nav-link"
          id="nav-discord"
        >
          <path d="M1032.7,48.8h-8.3c-0.5,0-0.8-0.1-1-0.3c-0.2-0.2-0.3-0.6-0.3-1.3V22.9c0-0.3,0-0.5,0-0.7 c0.1-0.6,0.5-0.9,1.2-0.9h8.3c1.4,0,2.4,0.5,3.2,1.5c0.8,1,1.2,2.3,1.2,4v16.6c0,1.7-0.4,3-1.2,4S1034.1,48.8,1032.7,48.8z M1027.8,25.6v18.9c0,0.7,0.1,1.1,0.2,1.3s0.5,0.3,1,0.3h3.6c0.8,0,1.4-0.2,1.7-0.6s0.5-1.2,0.5-2.2V26.8c0-1-0.2-1.8-0.5-2.2 c-0.3-0.4-0.9-0.6-1.7-0.6h-3.6c-0.5,0-0.9,0.1-1,0.3S1027.8,24.9,1027.8,25.6z"/>
          <path d="M1044.1,48.8h-2.1c-0.6,0-0.9-0.1-1-0.3c-0.1-0.2-0.2-0.6-0.2-1.4v-10c0-0.7,0.2-1.1,0.5-1.3 c0,0,0.4-0.1,1.2-0.3c0.5-0.1,0.8-0.6,0.8-1.5V22.9c0-1.1,0.4-1.6,1.1-1.6c0.7,0,1.1,0.5,1.1,1.6v24.3c0,0.7-0.1,1.2-0.2,1.4 C1045,48.7,1044.7,48.8,1044.1,48.8z"/>
          <path d="M1059.5,48.8h-9.3c-0.5,0-0.8-0.1-1-0.4c-0.2-0.2-0.3-0.7-0.3-1.3V41c0-0.7,0.1-1.2,0.2-1.4s0.5-0.3,1-0.3 h2.1c0.5,0,0.9,0.1,1,0.3c0.1,0.2,0.2,0.6,0.2,1.4v3.5c0,0.7,0.1,1.1,0.2,1.3s0.5,0.3,1,0.3h2.6c0.6,0,0.9-0.1,1.1-0.3 c0.2-0.2,0.2-0.6,0.2-1.3v-7c0-0.7-0.1-1.1-0.2-1.3c-0.2-0.2-0.5-0.3-1.1-0.3h-7.3c-0.5,0-0.8-0.1-1-0.3s-0.3-0.7-0.3-1.3V22.9 c0-0.3,0-0.5,0-0.7c0.1-0.6,0.5-0.9,1.2-0.9h9.5c0.2,0,0.4,0,0.5,0.1c0.5,0.1,0.7,0.6,0.7,1.6V28c0,1.1-0.4,1.6-1.1,1.6 c-0.7,0-1.1-0.5-1.1-1.6v-2.4c0-0.7-0.1-1.1-0.2-1.3c-0.2-0.2-0.5-0.3-1.1-0.3h-5.1c-0.6,0-0.9,0.1-1.1,0.3 c-0.2,0.2-0.2,0.6-0.2,1.3v5.9c0,0.7,0.1,1.1,0.2,1.3c0.2,0.2,0.5,0.3,1.1,0.3h7.3c0.2,0,0.4,0,0.5,0.1c0.5,0.1,0.7,0.6,0.7,1.6 v12.4c0,0.6-0.1,1.1-0.3,1.3C1060.5,48.7,1060.1,48.8,1059.5,48.8z"/>
          <path d="M1075.1,48.8h-9.5c-0.5,0-0.8-0.1-1-0.3c-0.2-0.2-0.3-0.6-0.3-1.3V22.9c0-0.3,0-0.5,0-0.7 c0.1-0.6,0.5-0.9,1.2-0.9h9.5c0.2,0,0.4,0,0.5,0.1c0.5,0.1,0.7,0.6,0.7,1.6v5.9c0,0.7-0.1,1.2-0.2,1.4c-0.1,0.2-0.5,0.2-1.1,0.2 h-2.1c-0.6,0-1-0.1-1.1-0.2c-0.1-0.2-0.2-0.6-0.2-1.4v-3.6c0-0.5-0.1-0.8-0.2-1s-0.4-0.2-0.9-0.2h-2.9c-0.5,0-0.9,0.1-1,0.3 c-0.1,0.2-0.2,0.6-0.2,1.3v18.9c0,0.7,0.1,1.1,0.2,1.3c0.2,0.2,0.5,0.3,1.1,0.3h5.2c0.6,0,0.9-0.1,1.1-0.3s0.2-0.6,0.2-1.3v-3 c0-1.1,0.4-1.6,1.1-1.6c0.7,0,1.1,0.5,1.1,1.6v5.7c0,0.7-0.1,1.2-0.2,1.4C1076,48.7,1075.7,48.8,1075.1,48.8z"/>
          <path d="M1092,48.8h-11.5c-0.5,0-0.8-0.1-1-0.3c-0.2-0.2-0.3-0.6-0.3-1.3V22.9c0-1.1,0.4-1.6,1.3-1.6h11.5 c0.2,0,0.4,0,0.6,0.1c0.5,0.1,0.7,0.6,0.7,1.6v24.3c0,0.3,0,0.5,0,0.7C1093.2,48.5,1092.8,48.8,1092,48.8z M1081.5,25.6v18.9 c0,0.7,0.1,1.1,0.2,1.3s0.5,0.3,1,0.3h4.6c0.5,0,0.9-0.1,1-0.3c0.2-0.2,0.2-0.6,0.2-1.3V34c0-0.9,0.4-1.4,1.1-1.4h0.6 c0.4,0,0.6-0.2,0.7-0.5c0.1-0.2,0.1-0.6,0.1-1.2v-5.3c0-0.7-0.1-1.1-0.2-1.3s-0.5-0.3-1-0.3h-7.1c-0.5,0-0.9,0.1-1,0.3 S1081.5,24.9,1081.5,25.6z"/>
          <path d="M1099.5,48.8h-2.1c-0.6,0-0.9-0.1-1.1-0.3c-0.2-0.2-0.2-0.6-0.2-1.4V22.9c0-0.3,0-0.5,0-0.7 c0.1-0.6,0.5-0.9,1.2-0.9h9.5c0.2,0,0.4,0,0.5,0.1c0.5,0.1,0.7,0.6,0.7,1.5v8.7c0,1.1,0.3,1.6,1,1.6h0.5c0.7,0,1,0.5,1,1.5v12.6 c0,0.7-0.1,1.2-0.2,1.4c-0.1,0.2-0.5,0.3-1.1,0.3h-2.1c-0.6,0-0.9-0.1-1.1-0.3c-0.2-0.2-0.2-0.6-0.2-1.4v-9.7 c0-0.7-0.1-1.1-0.2-1.3c-0.2-0.2-0.5-0.3-1.1-0.3h-2.7c-0.5,0-0.9,0.1-1,0.3c-0.2,0.2-0.2,0.6-0.2,1.3v9.7c0,0.7-0.1,1.2-0.2,1.4 C1100.5,48.7,1100.1,48.8,1099.5,48.8z M1098.3,25.6v6c0,0.7,0.1,1.1,0.2,1.3c0.2,0.2,0.5,0.3,1.1,0.3h5.2c0.6,0,0.9-0.1,1.1-0.3 c0.2-0.2,0.2-0.6,0.2-1.3v-6c0-0.7-0.1-1.1-0.2-1.3s-0.5-0.3-1.1-0.3h-5.2c-0.6,0-0.9,0.1-1.1,0.3 C1098.4,24.5,1098.3,24.9,1098.3,25.6z"/>
          <path d="M1123.8,48.8h-8.3c-0.5,0-0.8-0.1-1-0.3c-0.2-0.2-0.3-0.6-0.3-1.3V22.9c0-0.3,0-0.5,0-0.7 c0.1-0.6,0.5-0.9,1.2-0.9h8.3c1.4,0,2.4,0.5,3.2,1.5c0.8,1,1.2,2.3,1.2,4v16.6c0,1.7-0.4,3-1.2,4 C1126.3,48.3,1125.2,48.8,1123.8,48.8z M1118.9,25.6v18.9c0,0.7,0.1,1.1,0.2,1.3c0.2,0.2,0.5,0.3,1,0.3h3.6c0.8,0,1.4-0.2,1.7-0.6 c0.3-0.4,0.5-1.2,0.5-2.2V26.8c0-1-0.2-1.8-0.5-2.2c-0.3-0.4-0.9-0.6-1.7-0.6h-3.6c-0.5,0-0.9,0.1-1,0.3 C1119,24.5,1118.9,24.9,1118.9,25.6z"/>
        </g>
        <polygon
          @click="onNavLinkClick"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
          data-destination="mission"
          data-group="nav-mission"
          class="nav-area"
          points="597.8,9 602.1,69.5 765.6,67.3 763.4,9"
        />
        <polygon
          @click="onNavLinkClick"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
          data-destination="milestones"
          data-group="nav-milestones"
          class="nav-area"
          points="786.8,9 791.7,66.7 992.8,61.8 992.8,9"
        />
        <polygon
          @click="onNavLinkClick"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
          data-destination="https://discord.com/invite/DgGAfw2ZPE"
          data-group="nav-discord"
          class="nav-area"
          points="1007,9 1009.1,60.2 1141.6,60.2 1141.6,9"
        />
      </svg>
    </figure>
  </section>
</template>

<script>
export default {
  name: 'sec-hero',
  methods: {
    onNavLinkClick(event) {
      const { destination } = event.target.dataset;
      if (/https?/.test(destination)) {
        window.open(destination);
      } else {
        this.scrollTo(destination);
      }
    },
    scrollTo(dest) {
      const appHeader = document.querySelector('.app-header');
      const target = document.querySelector(`.sec-${dest}`).offsetTop;
      const buffer = 10;
      const top = appHeader.clientHeight + target + buffer;
      window.scrollTo({
        top,
        behavior: 'smooth'
      });
    },
    onMouseEnter(event) {
      const element = document.getElementById(event.target.dataset.group);
      if (element) element.classList.add('hover');
    },
    onMouseLeave(event) {
      const element = document.getElementById(event.target.dataset.group);
      if (element) element.classList.remove('hover');
    }
  }
};
</script>

<style lang='scss'>
@import '../styles/vars';

.sec-hero {
  position: relative;
  z-index: 2;

  .nav-area {
    cursor: pointer;
    fill: transparent;
  }
  
  .nav-link {
    fill: $color-beige;
    transition: all 0.15s ease;
  }
  
  .nav-link.hover {
    fill:$color-red;
  }
  svg {
    display: block;
  }
  svg image {
    overflow: visible;
    pointer-events: none;
  }
}
</style>