<template>
  <!-- <AppNav/> -->
  <router-view />
  <AppHeader/>
  <AppFooter/>
  <AppToastMessage/>
</template>

<script>
// import AppNav from './components/AppNav.vue'
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import AppToastMessage from '@/components/AppToastMessage.vue';

export default {
  name: 'App',
  components: {
    AppToastMessage,
    AppHeader,
    AppFooter
  }
}
</script>

<style lang='scss'>
  @import './styles/index.scss';
</style>
