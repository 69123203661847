<template>
  <section class="sec-why">
    <div class="inner">
        <h3 class="subtitle"
        data-subtitle='Why'
      >
        Why Become a Muttnik?
      </h3>
      <ul>
          <li>All original hand drawn art.</li>
          <li>First ever NFT random generator where the community minting decisions determine rarity. (Rarity changes with every minted Muttnik.)</li>
          <li>Amazing Muttnik community from around the world.</li>
          <li>Supportive Dev Team committed to longterm goals and development of new art and tech.</li>
          <li>Frequent "Muttups" on Twitter Spaces and Clubhouse with the Dev Team.</li>
          <li>Free Muttnik Giveaways at EVERY Muttup until sold out.</li>
          <li>Committed to the DOGE philosophy (Do Only Good Everyday.)</li>
          <li>Over $ 400,000 USD raised for charity in 2021 and growing.</li>
          <li>We love ALL of our four legged friends and aim to make more positive impact as the community grows.</li>
          <li>Because you're Pawesome!</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'sec-why'
};
</script>

<style lang='scss'>
@import '../styles/vars';
@import '../styles/mixins';

.sec-why {
  color: $color-green;
  background: lighten($color-beige, 5%);
  padding-bottom: var(--v-space);
  .inner {
    max-width: var(--max-width-l);
    padding: var(--v-space) var(--gutter) 0;
    position: relative;
    margin: auto;
  }

  ul {
    columns: 2;
    column-gap: var(--gutter);
  }

  li {
    max-width: var(--max-width-m);
    margin-bottom: 32px;
  }

  .subtitle::before {
    @include base-font(h1);
    color: $color-red;
  }

  .sec-offset {
    margin-top: var(--v-space);
  }

  .sec-offset .subtitle {
    @include base-font(h1);
    color: $color-red;
    font-family: $font-computer;
  }
}

</style>