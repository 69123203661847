<template>
  <section class="sec-rarity">
    <div class="inner">
      <div class="section">
        <h3
          class="subtitle"
          data-subtitle='Rarity'
        >
          That Is Truly Randomized
        </h3>
        <ul>
          <li>
            Each minting launches a random generated Star into orbit. Unique to
            the Cosmic Paws platform, the collector can choose to MINT or
            Regenerate. If a Star Muttnik is regenerated, it’s traits are returned to
            the generator. There is NO going back if you abandon a Star Muttnik.
          </li>
          <li>
            Full commercial rights for remix or reproduction, are granted to
            each token holder for their specific Star. They must list any sales
            as a “Remix” Star. This license does not contain ownership of any
            IP.
          </li>
          <li>
            No Bonding Curve. Discounts are given to Laika Muttnik holders.
          </li>
          <li>
            Each Muttnik is minted in order of launch.
          </li>
          <li>
            "For All Muttkind" is a credo that the Muttniks take seriously. Our
            Muttniks’ launchpad has been designed from inception to create an
            even playing field for all collectors throughout the universe.
          </li>
        </ul>
      </div>
      <div class="section">
        <h3
          class="subtitle"
          data-subtitle='Carbon-offset'
        >
          And Environmentally Friendly
        </h3>
        <ul>
          <li>Through our partnership with sustainability platform, Aerial, all NFTs 
            made available by Cosmic Paws are carbon-offset. </li>
          <li>Aerial is a sustainability platform that calculates the carbon emissions 
            based on associated energy use. The offsets will be allocated to high-quality 
            forest conservation projects verified by American Carbon Registry and to 
            emerging technologies such as Charm industrial that captures carbon from 
            biomass waste and permanently stores it underground.</li>
        </ul>
        <iframe
          frameborder="0"
          src="https://www.aerial.is/nft/embed?address=0xCa61AD7AaE3afBC15c2ba618612FfA2c2265C821&theme=dark"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'sec-rarity'
};
</script>

<style lang='scss'>
@import '../styles/vars';
@import '../styles/mixins';

.sec-rarity {
  --size: 100vw;
  --border-w: 8px;
  --depth: 24px;
  --gutter2: calc(var(--gutter) * 2);

  @include extrude(desaturate(darken($color-green, 5%), 5%), 1px, 5px, false);

  margin: auto;
  padding: var(--v-space) var(--gutter);

  .inner {
    margin: 0 auto;
    max-width: var(--max-width-m);
    padding: 0 var(--gutter);
    @include media(4-up) {
      max-width: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 64px;
    }
  }

  li {
    max-width: var(--max-width-m);
    margin-bottom: 32px;
  }
  .subtitle::before {
    @include base-font(h1);
    color: $color-red;
  }

  .section {
    margin-top: var(--v-space);
  }
}

</style>