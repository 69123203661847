<template>
  <section class="sec-generator">
    <ControlRoom
      :hasMinted='hasMinted'
      :isGenerating='isGenerating'
      :isMinting='isMinting'
      :hasFailedMinting='hasFailedMinting'
      :isWalletConnected='isWalletConnected'
      :isNFTSet='isNFTSet'
      :nftimg='nftimg'
      :onConnect='onConnect'
      :onGenerate='onGenerate'
      :onMint='onMint'
    />
    <GeneratorInstructions/>
  </section>
</template>

<script>
import axios from "axios";
import Web3 from "web3";
import GeneratorInstructions from '@/components/GeneratorInstructions.vue';
import ControlRoom from '@/components/ControlRoom.vue';
import WL from 'walletlink';
import Portis from '@portis/web3';
import cbSVG from '../assets/coinbase-wallet.svg';
import pinataSDK from '@pinata/sdk';
import Moralis from 'moralis';
import WalletConnectProvider from "@walletconnect/web3-provider";

export default {
  name: "sec-generator",
  components: {
    GeneratorInstructions,
    ControlRoom
  },
  data() {
    return {
      // The NFT image in the generator
      pinata: pinataSDK('2d3c22621e9865ab1db5', 'c9bebed7a343b3f6117ffb59fe80f68d11d2cfc08b71e53536b0f4fbf1fcad77'),
      api: "https://20211027t203401-dot-cosmic-paws.uc.r.appspot.com",
      defaultImage: require("@/assets/image/generator-default.gif"),
      nftimg: null,
      isNFTSet: false,
      nftdata: {},
      isWalletConnected: false,
      isGenerating: false,
      isModalActive: false,
      concatenatedAddress: "",
      ethUserId: null,
      hasMinted: false,
      isMinting: false,
      hasFailedMinting: false,
      special: true,
      provider: "",
      gateway: 'https://cosmicpaws.mypinata.cloud/ipfs/',
      signature: ''
    };
  },
  methods: {
    /**
     * Connect MetaMask wallet
     */
      async onConnect() {
       await this.setUserId();
      },

    /**
     * When the meta mask wallet accouns changes
     * @param {string[]} accounts
     */
    onAccountsChange(accounts) {
      if (accounts.length === 0) {
        this.isWalletConnected = false;
      } else {
        const [account] = accounts;
        const first5 = account.slice(0, 5);
        const last4 = account.slice(account.length - 4, account.length);
        this.concatenatedAddress = `${first5}...${last4}`;
        this.isWalletConnected = true;
        this.account = account;
      }
    },

    /**
     * @param {Object} error
     */
    onWalletConnectError(error) {
      if (error.code === 4001) {
        console.log("User has rejected the connection request.");
      } else {
        console.error(error.message);
      }
    },

    /**
     * Set the ETH user ID
     * @param {Proxy} ethereum
     */
    async setUserId() {
    const chainId = 1;
    const providerOptions = {
        'custom-walletlink': {
            display: {
                logo: cbSVG,
                name: 'Coinbase',
                description: 'Use WalletLink to connect to Coinbase',
            },
            options: {
                appName: 'Cosmic Paws',
                networkUrl: 'https://mainnet.infura.io/v3/e42b233fda434c1dbeffe8449d8e2763',
                chainId,
            },
            package: WL,
            connector: async (_, options) => {
                const { appName, networkUrl, chainId } = options
                const walletLink = new WL({
                    appName
                });
                const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
                await provider.enable();
                return provider;
            },
        },
        portis: {
            package: Portis,
            options: {
                id: "31c26f24-9748-4029-87d1-76ef5ba39b8a",
                chainId
            }
        },
        walletconnect: {
          package: WalletConnectProvider, 
          options: {
            infuraId: "0421d5ecf8894580a7bbfc5c1d3be0c9" 
          }
        }

    };
    const Web3Modal = require('web3modal')
    const web3Modal = new Web3Modal.default({
        theme: "dark",
        network: "mainnet",
        providerOptions
    });

    web3Modal.clearCachedProvider();
    //const user = await Moralis.authenticate({ provider: "walletconnect", chainId: 56 })
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    this.provider = web3;
    const accounts = await this.provider.eth.getAccounts()
    this.account = accounts[0];
    this.isWalletConnected = true;
    },

    /**
     * When the user clicks a button
     */
    async onGenerate() {
      if(this.isWalletConnected === true){
        this.hasMinted = false;
        this.isGenerating = true;
        this.hasFailedMinting = false;
        axios
        .get(`${ this.api }/createStar`)
        .then(({ data }) => {
          if (typeof data === 'object' && data.nftimg) {
            const { nftimg } = data;
            const [ base64Image ] = nftimg;
            this.nftimg = `data:image/png;base64,${ base64Image }`;
            this.signature = data.signature;
            this.isNFTSet = true;
            this.nftdata = data;
            this.isGenerating = false;
          }
        }).catch((err) => {
          this.isGenerating = false;
          this.hasFailedMinting = true;
          this.$store.commit('SEND_TOAST_MESSAGE', {
            message: err,
            type: 'info'
          });
          console.log(err)
          });
        } else{
          this.$store.commit('SEND_TOAST_MESSAGE', {
            message: "Please connect your wallet.",
            type: 'info'
          });
        }
    },

    /*
     * Set a default NFT image
     */
    setDefaultNFT() {
      this.nftimg = this.defaultImage;
    },

      
    async listTokensOfOwner(token, address) {

      const serverUrl = 'https://hxvx9bvywwak.usemoralis.com:2053/server'
      const appId = 'pg0n7L9JUAYki5C6ReIi1mXYP606Mnl72h68temi'
      Moralis.start({serverUrl, appId});
      const options = {
        address: address,
        chain: "eth"
      }
      var nfts = await Moralis.Web3API.account.getNFTs(options);
      var MuttnikTokens = [];
      for(var tokenNFT = 0; tokenNFT < nfts.result.length; tokenNFT++){
        if (nfts.result[tokenNFT].token_address == token.toLowerCase()){
          MuttnikTokens.push(Number(nfts.result[tokenNFT].token_id))
        }
      }
      return MuttnikTokens
    },

    /*
     * Minting an NFT
     */
  
    async onMint() {
      // const Web3Modal = require('web3modal')
      // const web3Modal = new Web3Modal.default();
      // const user = await Moralis.authenticate({ provider: "walletconnect", chainId: 56 })
      // const provider = await web3Modal.connect();
      // const web3 = new Web3(provider);
      // this.provider = web3;
      const contract = require("../../build/contracts/Star.json");
      const contractAddress = "0xa82F049eFc4C0AF4f441c1c157D071441B2A49cA";
      if(!this.provider) {
        this.setUserId()
      }
      const nftContract = new this.provider.eth.Contract(
        contract.abi,
        contractAddress
      );
      this.ethUserId = await this.provider.eth.net.getId();

      if (this.ethUserId === 1) {

        this.isMinting = true;

        try {
          const postdata = this.nftdata;
          const resp = await axios.post(`${this.api}/metadata`,postdata)
          const ipfslink = resp.data

          if (!ipfslink) {
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "Pinata failed please mint again",
                    type: 'error'
                    });
            throw resp;
          }

          if (!ipfslink.success) {
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "Pinata failed to load metadata. Please refresh the page and try again",
                    type: 'error'
                    });
            throw ipfslink.message;
          }
          const isStarTaken = await nftContract.methods.isStarMinted(ipfslink.hash).call();
          if (isStarTaken) {
              console.log("Already Minted!");
              this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "This Star has already been minted! Try regenerating",
                    type: 'error'
                    });
              this.hasMinted = false;
              this.isMinting = false;
              this.hasFailedMinting = true;
              return;
          }

          
          const purchaseprice = await nftContract.methods.mintCost().call()
          const txdata = {
            from: this.account,
            value: purchaseprice
          };
  
          const postdata2 = {"cid":ipfslink.hash, "sig":this.signature}
    
          const ret = await axios.post(`${this.api}/uizldSU`,postdata2)
          if(!ret.data){
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "Invalid return data",
                    type: 'error'
                    });
            throw Error("Server failed", ret)
          }
          console.time('moralis')
          const muttnikTokens = await this.listTokensOfOwner('0xCa61AD7AaE3afBC15c2ba618612FfA2c2265C821', this.account)
          console.timeEnd('moralis')
          const validtoken = []
          if (muttnikTokens.length != 0){
            muttnikTokens.sort(function(a, b) {
              return a - b;
            });
            for (var i of muttnikTokens) {
              const muttnikbought = await nftContract.methods.isMuttnikRedeemed(i).call()
              if (!muttnikbought){
                validtoken.push(i)
                break
              }
            }
          }
          console.log(validtoken)
          const nextMutt = await nftContract.methods.StarID().call()
          if(Number(nextMutt) == 3333){
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "Star Muttniks SOLD OUT! Check out opensea for secondary sales.",
                    type: 'warning'
                    });
            this.isMinting = false;
            this.hasMinted = false;
            return
          }
          const paused = await nftContract.methods.paused().call()
          if(!paused){
            const activeWL = await nftContract.methods.whiteListOn().call()
            if (activeWL){
              const permissions = await nftContract.methods.Whitelist(this.account).call()
              if(permissions.approvedTokens != 0) {
                if (validtoken.length != 0){
                  if (validtoken[0]< 101){
                    txdata.value = 0;
                  }
                  else {
                    txdata.value = purchaseprice/2;
                  }
                }else{
                txdata.value = permissions.mintPriceWei*10000000000000000
                }
              } 
              else {
                if (validtoken.length != 0){
                  if (validtoken[0]< 101){
                    txdata.value = 0;
                  }
                  else {
                    txdata.value = purchaseprice/2;
                  }
                } else { 
                    this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "You are not on the white list. Please wait for public sale or contact us via discord",
                    type: 'error'
                    });
                  this.isMinting = false;
                  this.hasMinted = false;
                  return 
                }
              }
            } else{
              const redeemable = await nftContract.methods.redeemable().call()
              if(redeemable){
                if (validtoken.length != 0){
                    if (validtoken[0]< 101){
                      txdata.value = 0;
                    }
                    else {
                      txdata.value = purchaseprice/2;
                    }
                  }
              } else{
                txdata.value = purchaseprice;
              }
            }
          }
          else{
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "Contract is currently paused for minting",
                    type: 'warning'
                    });
            this.isMinting = false;
            this.hasMinted = false;
            return
          }
          

          const weibalance = await this.provider.eth.getBalance(this.account);
          const strethbalance = await this.provider.utils.fromWei(String(txdata.value));
          if (Number(weibalance) < Number(txdata.value)) {
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: `You must have at least ${strethbalance} ETH in your account to purchase a Star`,
                    type: 'warning'
                    });
            this.isMinting = false;
            this.hasMinted = false;
            return;
          }

          const responsetx = await nftContract.methods
            .mintStar(
              ipfslink.hash,
              ret.data,
              validtoken
            )
            .send(txdata);

          this.isMinting = false;

          if (responsetx.status) {
            this.hasMinted = true;
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "You successfully minted a Muttnik!",
                    type: 'info'
                    });
            console.log("You successfully minted a Muttnik!");
            }
        } catch (err) {
          if (err.code === 4001) {
            this.isMinting = false;
            this.hasMinted = false;
            return;
          }
          if (err.code == -32000){
            this.isMinting = false;
            this.hasMinted = false;
            this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "You do not have enough ETH to cover mint and gas. Mint is 0.06 ETH",
                    type: 'warning'
                    });
            return;
          }
          this.isMinting = false;
          this.hasMinted = false;
          this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: err.code,
                    type: 'error'
                    });
          return;
        }
      } else {
        this.$store.commit('SEND_TOAST_MESSAGE', {
                    message: "Please switch your Metamask network to Ethereum Mainnet",
                    type: 'error'
                    });
        this.hasMinted = false;
        this.isMinting = false;
      }
    },
  },
  mounted() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", this.onAccountsChange);
    }
    this.setDefaultNFT();
  },
};
</script>

<style scoped lang='scss'>
@import "../styles/vars";
@import "../styles/mixins";

.sec-generator {
  position: relative;
}

.meta-mask-error {
  text-align: center;
  img {
    width: 100%;
    display: block;
    max-width: 60px;
    margin: 0 auto 15px;
  }
}

</style>