<template>
  <header
    class="app-header"
    :class="[$route.name]"
  >
    <!-- <figure class="image tl">
      <img src="@/assets/image/header-tl.png" alt="" />
    </figure>
    <figure class="image br">
      <img src="@/assets/image/header-br.png" alt="" />
    </figure> -->
    <p class="for-all">
      <span>For all</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 210 210"
        class="sparkle"
      >
        <path d="M102,17.5C93.8,83,93.4,84.7,89.7,82.2c-2-1.4-12.5-10-23.7-19c-11.2-9.2-18.8-14.9-17-12.7c1.8,2,10.4,12.5,19.2,23.3 l16.1,19.6l-6.5,1.2c-3.5,0.8-22.9,3.3-43.1,5.9c-20.2,2.6-35.7,5.1-34.3,5.9c1.2,0.6,18.6,3.1,38.6,5.5c20,2.6,38.2,5.1,40.6,5.7 c4.1,1.4,3.1,2.9-13.1,22.7c-17.8,21.6-21.4,26.7-13.1,19.4c12.5-11.2,39-32.3,39.6-31.6c0.4,0.4,3.3,20,6.3,43.3 c2.9,23.5,5.9,41.2,6.7,39.2c0.6-2,3.1-19.8,5.7-39.8c2.6-20,5.1-37.8,5.7-39.8c1.2-2.9,4.3-1,20.8,12.5c10.8,8.8,21,17,22.5,18.2 c3.3,2.4,4.3,3.5-18.4-24.3c-18.2-21.9-21-19.4,28.2-25.3c18.8-2.3,36.5-4.7,39.2-5.5c2.9-0.6-12.7-3.5-38.2-6.9 c-23.5-3.1-43.3-5.9-43.7-6.5c-0.8-0.6,20.4-27.1,31.6-39.8c7.2-8,2.2-4.5-19.4,13.1c-11.8,9.8-21.6,17.2-21.9,16.9 c-0.4-0.4-3.1-19.2-6.1-41.9c-2.9-22.7-5.9-41.7-6.3-42.3C104.8-1.1,103.4,6.9,102,17.5z"/>
      </svg>
      muttkind
    </p>
    <p>
      Muttnik NFTs celebrate the legendary cosmic canine friends
      who paved the way for human space flight.
    </p>
    <button
      @click='scrollToGenerator'
    >
      Mint Your Star Muttnik
    </button>
  </header>
</template>

<script>

export default {
  name: 'app-header',
  methods: {
    scrollToGenerator(event) {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>


<style lang='scss'>
@import '../styles/index.scss';

.app-header {
  position: relative;
  padding: calc(var(--v-space) * 1.5) var(--gutter) var(--v-space);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p.for-all {
    position: relative;
    font-family: $font-computer;
    color: $color-green6;
    @include fluid-type(60px, 122px);
    text-align: center;
    line-height: 0.9;
    margin: 0 0 20px;
    cursor: pointer;

    a {
      color: inherit;
      text-decoration: none;
    }
    span {
      display: block;
      color: transparent;
      -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $color-beige;
    }

    .sparkle {
      top: -30%;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      stroke: $color-red;
      stroke-width: 2;
      fill: none;
      width: 1em;
      animation: rotate 10s linear infinite;
    }
  }

  p {
    margin: 0 auto 32px;
    text-align: center;
    @include base-font(h4);
    max-width: var(--max-width-m);
    padding: 0 var(--gutter);
  }

  svg.icon {
    cursor: pointer;
    stroke-linecap: square;
    stroke-linejoin: miter;
    stroke-width: 1;
    fill: none;
    stroke: $color-red;
    stroke-miterlimit: 10;
    animation: pulse-down 1.4s linear infinite;
    transition: stroke 0.15s ease;
    &:hover {
      stroke: $color-beige;
    }
  }

  .image {
    width: 100%;
    padding: 0;
    position: absolute;
      max-width: 300px;
    @include media(3-up) {
      max-width: 420px;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .image.tl {
    top: 0;
    left: 0;
  }
  .image.br {
    bottom: 0;
    right: 0;
  }

  button {
    @include media(2-down) {
      font-size: 14px;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes pulse-down {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  30% {
    transform: translate3d(0, 5px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  100% {
    opacity: 0;
  }
}
</style>