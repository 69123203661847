<template>
  <div class="app-nav-links">
    <a
      v-for='link in links'
      :key=link.url
      :href="link.url"
      target="_blank"
      rel="noopener">
      {{link.text}}
    </a>
  </div>
</template>

<script>
export default {
  name: 'app-nav-links',
  data() {
    return {
      links: [
        {
          text: 'OpenSea',
          url: 'https://opensea.io/collection/muttniks-star'
        },
        {
          text: 'Twitter',
          url: 'https://twitter.com/PawsInSpace'
        },
        {
          text: 'Discord',
          url: 'https://discord.com/invite/DgGAfw2ZPE'
        }
      ]
    }
  }
};
</script>
