<template>
  <footer class="app-footer">
    <div class="inner">
      <div class="col">
        <router-link to="/" class="logo">
          <AppLogo layout='horizontal'/>
        </router-link>
      </div>
      <div class="col">
        <nav>
          <h5>Cosmic Paws</h5>
          <a @click="onNavLinkClick" data-destination="mission">Mission </a>
          <a @click="onNavLinkClick" data-destination="milestones">Road-Map </a>
          <a
            @click="onNavLinkClick"
            data-destination="https://opensea.io/collection/muttniks"
            >Laika Collections
          </a>
          <a
            @click="onNavLinkClick"
            data-destination="https://muttniks.medium.com/cosmic-paws-team-muttniks-nfts-97c29f3b3bc4"
            >Meet the Team
          </a>
          <a
            @click="onNavLinkClick"
            data-destination="https://discord.com/invite/DgGAfw2ZPE"
            >Discord
          </a>
          
        </nav>
      </div>
      <div class="col">
        <nav>
          <h5>Legal</h5>
          <router-link to="/terms-of-service">Terms</router-link>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
        </nav>
      </div>
      <p class="copyright">&copy;Cosmic Paws {{ getCurrentYear() }}</p>
    </div>
  </footer>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue';

export default {
  name: "app-footer",
  components: {
    AppLogo
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    onNavLinkClick(event) {
      event.preventDefault();
      const { destination } = event.target.dataset;
      if (/https?/.test(destination)) {
        window.open(destination);
      } else {
        this.scrollTo(destination);
      }
    },
    scrollTo(dest) {
      const appHeader = document.querySelector(".app-header");
      const target = document.querySelector(`.sec-${dest}`).offsetTop;
      const buffer = 10;
      const top = appHeader.clientHeight + target + buffer;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang='scss'>
@import "../styles/vars";
@import "../styles/mixins";

footer.app-footer {
  --size: 100vw;
  --border-w: 8px;
  --depth: 24px;
  
  @include extrude(desaturate(darken($color-green5, 0%), 0%), 1px, 5px, false);

  position: relative;
  padding: var(--v-space) calc(var(--gutter) * 2);

  .inner {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    max-width: var(--max-width-l);
    @include media(3-up) {
      grid-template-columns: min(300px, 420px) auto auto;
    }
  }

  a.logo {
    width: 100%;
    fill: $color-red;
    max-width: 136px;
    margin-bottom: 32px;
  }

  nav {
    display: flex;
    flex-direction: column;
    h5 {
      @include base-font();
      color: $color-green6;
      margin-bottom: 16px;
      @include media(3-up) {
        margin-bottom: 32px;
      }
    }
    a {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 15px;
      color: $color-beige;
      text-decoration: none;
    }
    a:hover {
      color: lighten($color-beige, 15%);
    }
  }

  .copyright {
    margin-top: 64px;
    grid-column: initial;
    @include media(3-up) {
      grid-column: 2/3;
    }
  }
}
</style>