<template>
  <section class="sec-about">
    <p class="type-tagline">
      Cosmic Paws honors our Earth famous space dogs now with Star:
    </p>

    <div class="gallery">
      <div class="gallery-image image">
        <img src="@/assets/image/1.jpg" alt="" />
      </div>
      <div class="gallery-image image">
        <img src="@/assets/image/2.jpeg" alt="" />
      </div>
      <div class="gallery-image image">
        <img src="@/assets/image/3.jpeg" alt="" />
      </div>
      <div class="gallery-image image">
        <img src="@/assets/image/4.jpeg" alt="" />
      </div>
      <div class="gallery-image image">
        <img src="@/assets/image/5.jpeg" alt="" />
      </div>
      <div class="gallery-image image">
        <img src="@/assets/image/6.jpeg" alt="" />
      </div>
    </div>

    <p>
      Muttniks are the Earth’s first Cosmic Paw pioneers. Our artistic
      process: The Muttniks team is a group of real life friends, actual
      kind humans, true dog lovers (and all animals, really), working
      space professionals, and career artists–ensuring the Muttniks
      history, legacy, and bravery is remembered, preserved, retold, and
      celebrated on the blockchain. The Muttniks actual bravery is
      unquestioned and unparalleled. Their sacrifice is real. And their
      contribution, until now, has gone largely unrecognized. In real
      life, the space flights of the real Muttniks contributed immensely
      to the advent and safety of space flight and ultimately humankind’s
      ability to be better (kinder) human beings, and worthy of becoming a
      multiplanetary species.
    </p>
    <p>
      <strong>Laika, Tsygan, Dezik, Lisa,</strong> and all the Muttniks
      (there have been nearly 100 dogs from all the world’s space programs
      who have been to space) are names we get to hold high and to revere,
      especially those special Muttniks who made the ultimate sacrifice!
      Muttniks NFTs are placeholders in history–until the time when we can
      invent a time machine and go round them up and save them. (The
      actual plan!)
    </p>
    <p>
      The Muttniks NFTs collection is generated from randomized original
      character layers and integrated into a ERC-721 contract. Files are
      backed by the Inter-Planetary-File-Storage system (IPFS) and registered 
      on the Ethereum Blockchain.
    </p>
    <div class="bullets">
      <i class="top"/>
      <i class="bottom"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionAbout'
};
</script>

<style lang='scss'>
@import '../styles/vars';
@import '../styles/mixins';

.sec-about {
  --size: 100vw;
  --border-w: 8px;
  --depth: 24px;
  
  @include extrude($color-green6, 1px, 5px, false);
  position: relative;
  color: $color-green;
  padding: calc(var(--v-space) * 2) calc(var(--gutter) * 2);
  max-width: var(--max-width-m);
  margin: auto;

  @include media(2-up) {
    padding: calc(var(--v-space) * 1) calc(var(--gutter) * 1);
  }

  @include media(4-up) {
    max-width: var(--max-width-l);
    padding: calc(var(--v-space) * 1) calc(var(--gutter) * 2);
  }
  .type-tagline {
    color: $color-green;
    max-width: var(--max-width-m);
  }

  p:not(.type-tagline) {
    max-width: var(--max-width-m);
    color: currentColor;
  }
}

.gallery {
  --image-size: 1fr;
  --columns: 2;
  margin-bottom: 30px;
  margin-left: 0;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(var(--columns), var(--image-size));
  position: relative;
  z-index: 10;

  @include media(3-up) {
    --columns: 3;
  }

  @include media(5-up) {
    --columns: 6;
    --image-size: 200px;
    margin-left: calc(calc(var(--gutter) * -1) + calc(var(--image-size) * -0));
    margin-top: var(--v-space);
    margin-bottom: calc(var(--v-space) * 2);
  }

  .gallery-image {
    width: 100%;
    height: var(--image-size);
    display: block;
    position: relative;
    background: $color-black;

    &:first-child {
      @include media(5-up) {
        display: none;
      }
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  // Stagger
  .gallery-image:nth-child(even) {
    top: 0;
    @include media(5-up) {
      top: calc(var(--image-size) / 2);
    }
  }
}

</style>