import { createRouter, createWebHistory } from 'vue-router';

// Views
import Home from '@/views/Home';
import TermsOfService from '@/views/TermsOfService.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/terms-of-service',
    component: TermsOfService
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      window.scrollTo(0, 0);
      return { x: 0, y: 0 };
    }
  }
});

export default router;
