<template>
  <main class="home">
    <SectionGenerator/>
    <SectionHero/>
    <SectionCountdown/>
    <SectionRoadmap/>
    <SectionWhy/>
    <SectionMission/>
    <SectionAbout/>
    <SectionFunFacts/>
    <SectionRarity/>
  </main>
</template>

<script>
//<img class="hi" src="@/assets/backsoon.jpg"/>
import SectionGenerator from '@/components/SectionGenerator'
import SectionHero from '@/components/SectionHero.vue';
import SectionCountdown from '@/components/SectionCountdown.vue';
import SectionMission from '@/components/SectionMission.vue';
import SectionAbout from '@/components/SectionAbout.vue';
import SectionRoadmap from '@/components/SectionRoadmap.vue';
import SectionWhy from '@/components/SectionWhy.vue';
import SectionFunFacts from '@/components/SectionFunFacts.vue';
import SectionRarity from '@/components/SectionRarity.vue';

export default {
  components: {
    SectionGenerator,
    SectionHero,
    SectionCountdown,
    SectionMission,
    SectionAbout,
    SectionRoadmap,
    SectionWhy,
    SectionFunFacts,
    SectionRarity
  }
};
</script>

<style lang='scss'>
@import '../styles/vars';
@import '../styles/mixins';
.hi{
  width: 100vw;
}
main.home {
  width: 100%;
  min-height: 100%;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
