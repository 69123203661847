<template>
  <div class='control-room'>
    <AppLogo/>
    <div class="gen-panels">
      <GenSide
        text='For All Muttkind'
        :position='1'
        class="left"
      >
        <span class="space-map"><img src="@/assets/image/gen-space-map.svg"/></span>
        <span class="world-map"><img src="@/assets/image/gen-world-map.svg"/></span>
        <span class="space-screen rocket">
          <span class="space-screen-text">Live</span>
          <span class="space-screen-text">Screen 1</span>
          <img src="@/assets/image/gen-rocket.jpg"/>
        </span>
        <span class="panel-grid"/>
      </GenSide>
      <div class="gen-center">
        <AppNavLinks
          class="gen-social gen-top-panel"
        />
        <figure
          class='gen-nft'
          v-if='!hasFailedMinting && !isMinting'
        >
          <img
            width="1676"
            height="1676"
            :class="{ 'has-minted': hasMinted }"
            :src="nftimg"
          />
          <transition name='scan'>
            <img
              v-show='isGenerating'
              width="1676"
              height="1676"
              class="scanner"
              src="@/assets/image/scanner.gif"
            />
          </transition>
          <transition name='scan'>
            <img
              v-show='hasMinted'
              width="1676"
              height="1676"
              class="minted"
              src="@/assets/image/has-minted.jpg"
            />
          </transition>
        </figure>

        <div
          class='gen-nft gen-message minting'
          v-if='isMinting'
        >
          <h2 class="gen-message-title">Minting</h2>
          <p class="gen-message-text">in progress</p>
          <div class="loader2"/>
        </div>

        <div
          class='gen-nft gen-message failed'
          v-if='hasFailedMinting'
        >
          <h2 class="gen-message-title">Failed</h2>
          <p class="gen-message-text">Something went wrong.</p>
          <ul class='gen-messge-list'>
            <li>Your NFT was not successfully minted.</li>
            <li>Please try to mint another NFT.</li>
          </ul>
        </div>

        <div class="gen-controls panel-floor">
          <button
            @click="onConnect"
            :class="{ connected: isWalletConnected }"
            :disabled='isWalletConnected'
            class="connect extrude"
          >
            {{buttonTextConnect}}
          </button>
          <button
            @click="onGenerate"
            :disabled='isGenerating || isMinting'
            class="generate extrude"
          >
            {{buttonTextGenerate}}
          </button>
          <button
            @click="onMint"
            :disabled='!isWalletConnected || isGenerating || !isNFTSet || isMinting || hasMinted'
            class="mint extrude"
          >
            {{buttonTextMint}}
          </button>
        </div>
        <div class="mint-price">
          <p>Mint Price: 0.06 ETH</p>
        </div>
      </div>
      <GenSide
        text='Muttnik NFTs'
        :position='0'
        class="right"
      >
      <span class="space-screen rocket">
        <span class="space-screen-text">Live</span>
        <span class="space-screen-text">Screen 2</span>
        <img src="@/assets/image/gen-rocket-2.jpg"/>
      </span>
      <span class="space-screen rocket">
        <span class="space-screen-text">Live</span>
        <span class="space-screen-text">Screen 3</span>
        <img src="@/assets/image/gen-rocket-3.jpg"/>
      </span>
      <span class="screen-ui">
        <ScreenUI
          v-for='n in 8'
          :key='n'
        />
      </span>
      <span class="panel-grid"/>
      </GenSide>
    </div>

    <div class="gen-dog-control-group">
      <figure class="gen-control-station">
        <img
          width='1440'
          height='87'
          alt='Control Room Station'
          src='@/assets/image/gen-control-station.png'
        />
      </figure>
      <figure class="gen-dogs">
        <img
          width='1289'
          height='298'
          alt='Control Room Dogs'
          src='@/assets/image/gen-dogs.png'
        />
      </figure>
    </div>

  </div>
</template>

<script>
import { h } from 'vue';
import AppLogo from '@/components/AppLogo.vue';
import AppNavLinks from '@/components/AppNavLinks.vue';
import { getRandomInt } from '@/utils';

const GenSide = {
  name: "GenSide",
  props: {
    text: {
      type: String,
      default: ''
    },
    position: {
      type: Number,
      default: 0
    }
  },
  render() {
    const PanelGrid = h('span', { class: 'panel-grid'});
    const EmptyTopPanels = Array.from({ length: 2 }, (_, index) =>
      h('div', { class: 'empty-panel' }, [
        PanelGrid,
        index === this.position && this.text ? h('span', { class: 'panel-top-text' }, this.text) : null
      ]));
    const EmptyBottomPanels = Array.from({ length: 2 }, () => h('div', { class: 'empty-panel' }, [h('div', {class: 'panel-grid'}) ]));
    const GenTopPanel = h('div', { class: 'gen-top-panel' }, EmptyTopPanels);
    const GenBottomPanel = h('div', { class: 'gen-bottom-panel panel-floor' }, EmptyBottomPanels);
    const GenPanel = h('div', { class: 'gen-panel' }, [this.$slots.default && this.$slots.default()]);
    return h('div', { class: 'gen-side'}, [
      GenTopPanel,
      GenPanel,
      GenBottomPanel
    ]);
  }
};

const ScreenUI = {
  name: "ScreenUI",
  data() {
    return {
      chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    }
  },
  computed: {
    stylePill() {
      return { 
        gridRowEnd: `span ${getRandomInt(0, 2)}`,
        gridColumnEnd: `span ${getRandomInt(0, 3)}`,
        transform: `translateZ(${getRandomInt(-10, 10)}px)`
      };
    },
    styleBg() {
      return { 
        animationDuration: `${getRandomInt(15, 60)}s`
      };
    },
    number() {
      return Math.round(Math.random() * 1000) / 1000;
    },
    text() {
      let text = []
      for (let i = 0; i < 3; i++) {
        text.push(this.chars.charAt(Math.floor(Math.random() * this.chars.length)));
      }
      return text.join('');
    }
  },
  render() {
    const Num = h('div', { class: 'num'}, this.number);
    const Text = h('div', { class: 'text'}, this.text);
    const Bg = h('div', { class: 'bg', style: this.styleBg });
    return h('div', { class: 'ui-pill', style: this.stylePill }, [ Bg, Text, Num ]);
  }
};

export default {
  name: 'control-room',
  components: {
    AppNavLinks,
    AppLogo,
    GenSide,
    ScreenUI
  },
  props: {
    hasMinted: {
      type: Boolean,
      default: false
    },
    isGenerating: {
      type: Boolean,
      default: false
    },
    isMinting: {
      type: Boolean,
      default: false
    },
    hasFailedMinting: {
      type: Boolean,
      default: false
    },
    isWalletConnected: {
      type: Boolean,
      default: false
    },
    isNFTSet: {
      type: Boolean,
      default: false
    },
    nftimg: {
      type: String,
      default: require('@/assets/image/generator-default.jpg')
    },
    onConnect: {
      type: Function,
      default: () => {}
    },
    onGenerate: {
      type: Function,
      default: () => {}
    },
    onMint: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    buttonTextConnect() {
      return this.isWalletConnected ? 'Connected' : 'Connect';
    },
    buttonTextGenerate() {
      return !this.isGenerating ? 'Generate': 'Generating';
    },
    buttonTextMint() {
      return !this.isMinting ? 'Mint' : 'Minting';
    }
  }
};
</script>

<style lang='scss'>

@use "sass:math";
@import "../styles/vars";
@import "../styles/mixins";

$color-bg: darken($color-green5, 5%);
$color-panel: $color-beige;
$color-panel-bg: desaturate(darken($color-beige, 10%), 10%);
$color-panel-inner: desaturate(darken($color-beige, 10%), 10%);
$panel-angle: 30deg;

/*
  Box Style
  ----------------------------------------
*/

.control-room {
  --size: 100vw;
  --border-w: 8px;
  --depth: 24px;
  --button-h: 48px;
  --panel-h: 64px;

  padding-top: var(--nav-h);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: $color-bg;
  user-select: none;

  @include media(2-up) {
    --size: 600px;
    --border-w: 24px;
    --depth: 16px;
    --button-h: 62px;
    --panel-h: 80px;
  }

  @include media(3-up) {
    --size: 420px;
  }

  @include media(4-up) {
    --size: 500px;
  }
}

.control-room .app-logo {
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 120px;
  height: var(--nav-h);
  fill: $color-red;
}

/*
  Panels
  ----------------------------------------
*/

.gen-panels {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: var(--size);
  perspective: 1400px;
  transform-style: preserve-3d;
  grid-template-columns: auto;
  margin-bottom: 24px;
  @include media(2-up) {
    grid-template-columns: 1fr var(--size) 1fr;
  }
}

/*
  NFT Image
  ----------------------------------------
*/

.gen-nft {
  width: var(--size);
  height: var(--size);
  position: relative;
  @include extrude($color-panel-inner, 1px, 5px);
  img {
    --pos: calc(var(--border-w) + calc(var(--depth) / 2));
    --img-size: calc(calc(100% - calc(var(--border-w) * 2)) - var(--depth));
    position: absolute;
    top: var(--pos);
    left: var(--pos);
    width: var(--img-size);
    height: var(--img-size);
    object-position: cover;
  }
  img[src*='default'] {
    filter: hue-rotate(-85deg) saturate(0.5);
  }
}

.gen-minting {
  position: absolute;
  inset: calc(var(--border-w) + var(--depth) /2);
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(var(--per-dir), 1fr);
  grid-template-rows: repeat(var(--per-dir), 1fr);
}

.gen-nft.gen-message {
  padding: calc(var(--depth) + var(--border-w));
  display: flex;
  flex-direction: column;
  padding-bottom: var(--depth);
  margin-bottom: 0;
  
  &.failed {
    .gen-message-title {
      color: $color-red;
    }
  }
  &.minting {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .gen-message-title {
      color: $color-blue;
    }
  }
  .gen-message-title,
  .gen-message-text {
    font-family: $font-computer;
    @include base-font(h1);
    margin: 0;
    line-height: 1;
  }
  .gen-messge-list {
    --pad-l: 24px;
    margin-top: 40px;
    @include base-font();
    padding-left: var(--pad-l);
    height: 100%;
    overflow: hidden;

    li {
      --size: 8px;
      --mb: 16px;
      $color-bullet: lighten($color-green, 16%);
      margin: 0 0 var(--mb) var(--size);
      position: relative;
      list-style: none;
      position: relative;
      @include base-font(large);
      line-height: 1.25;
      &::before {
        content: '';
        position: absolute;
        left: calc(var(--pad-l) * -1);
        top: 10px;
        width: var(--size);
        height: var(--size);
        border-radius: var(--size);
        background: $color-green6;
        border: 2px solid $color-bullet;
        transform: scale(1.5);
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 10px;
        left: calc(calc(var(--pad-l) * -1) + calc(var(--size)/2));
        bottom: calc(var(--mb) * -2);
        width: 1px;
        background: $color-bullet;
      }
    }
    li:last-child {
      height: 100%;
    }
  }
}

.gen-nft img.has-minted {
  transform: scale(0.7) translateY(40px);
}

.gen-social {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  a {
    --border-w: 4px;
    --depth: 4px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @include base-font(small);
    @include extrude($color-panel, false, 2px);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    transition: all 0.1s linear;
    @media(hover:hover) {
      &:hover {
        color: $color-white;
        text-shadow: 0 0 10px saturate(darken($color-panel, 50%), 100%);
        @include extrude(darken($color-panel, 20%), false, 2px);
      }
    }
  }
}

.gen-top-panel {
  background: $color-panel-bg;
  min-height: var(--panel-h);
  padding: 8px 8px;
  box-shadow: 0 -2px 2px 0 lighten($color-panel-bg, 50%);
}

.panel-top-text {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
  @include base-font(large);
  color: darken($color-beige, 0%);
  font-family: $font-computer;
  @include media(3-up) {
    display: flex;
  }
}

.gen-bottom-panel {
  background: $color-panel-bg;
  position: relative;
  bottom: 0;
  width: 100%;
  min-height: var(--panel-h);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/*
  Sides
  ----------------------------------------
*/

.gen-side {
  position: relative;
  width: 100%;
  display: none;
  @include media(2-up) {
    display: block;
  }
  .gen-top-panel {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 8px;
  }
  .empty-panel {
    --border-w: 4px;
    --depth: 4px;
    width: 100%;
    height: 100%;
    @include extrude($color-panel, 0px, 0px);
    display: block;
    &::after {
      box-shadow: none;
    }
  }
  .empty-panel .panel-grid {
    --edge: 8px;
  }
}

.gen-side .gen-panel {
  width: 100%;
  height: var(--size);
  position: relative;
  @include extrude($color-panel-inner, 1px, 5px);
  padding-left: calc(var(--depth) + var(--border-w));
  padding-right: calc(var(--depth) + var(--border-w));
  padding-top: calc(var(--border-w) + var(--depth));
  padding-bottom: calc(var(--border-w) + var(--depth));
  display: grid;
  grid-gap: 8px;
  overflow: hidden;
  grid-template-columns: auto;
  grid-template-rows: auto;

  @include media(4-up) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  span {
    display: block;
    overflow: hidden;
  }
  .space-map {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    z-index: 1;
    background: $color-bg;
    border-radius: 1000px;
    position: relative;
    aspect-ratio: 1/1;
    align-self: flex-start;
    justify-self: flex-end;
    @include media(3-up) {
      display: flex;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    // Needle
    &::before {
      $color-needle: saturate($color-green, 50%);
      content: '';
      position: absolute;
      width: 1px;
      height: 45%;
      bottom: 50%;
      background: $color-needle;
      transform-origin: 50% 100%;
      box-shadow: 
        -1px 0 5px $color-needle,
        -5px 0 15px 2px $color-needle,
        -10px 0 35px 0px $color-needle,
        -40px 0 65px 0px $color-needle;
      animation: radar-needle 20s linear infinite;
    }
  }

  .world-map {
    grid-column: span 2;
    grid-row: 2;
    width: 100%;
    height: 100%;
    display: none;
    @include media(3-up) {
      display: flex;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .space-screen {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: $color-bg;
    border-radius: 8px;
    overflow: hidden;
    display: none;
    @include media(3-up) {
      display: flex;
    }
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
      mix-blend-mode: multiply;
      border-radius: inherit;
    }
    img {
      border-radius: inherit;
      width: 100%;
      height: 100%;
      border: 1px outset $color-bg;
      filter: saturate(0.2) brightness(0.4);
    }

    &.rocket::after {
      content: '';
      position: absolute;
      inset: 4px;
      background-image: linear-gradient($color-green 0.05em, transparent 0.05em);
      background-size: 100% 0.75em;
      background-position: 0 0%;
      z-index: 2;
      mix-blend-mode: overlay;
      animation: scan-lines 2s linear infinite;
      border-radius: inherit;
    }
  }
  .space-screen-text {
    position: absolute;
    z-index: 1;
    color: $color-red;
    display: flex;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    @include base-font(small);
    &:nth-of-type(1) {
      top: 8px;
      left: 8px;
      &::before {
        content: ' ';
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: $color-red;
        display: inline-block;
        margin-right: 4px;
      }
    }
    &:nth-of-type(2) {
      bottom: 8px;
      right: 8px;
      color: $color-green3;
    }
  }
}

@keyframes scan-lines {
  0% {
    background-position: 0 0%;
  }
  30% {
    background-position: 0 -200%;
  }
  60% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 -500%;
  }
}

/*
  Panel Grid
  ----------------------------------------
*/

.panel-grid {
  --edge: calc(var(--depth) + var(--border-w) - 8px);
  position: absolute;
  top: var(--edge);
  right: var(--edge);
  bottom: var(--edge);
  left: var(--edge);
  background-image:
    linear-gradient(rgba($color-white, 0.04) .1em, transparent .1em),
    linear-gradient(90deg, rgba($color-white, 0.04) .1em, transparent .1em);
  background-size: 0.5em 0.5em;
}

@keyframes radar-needle {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

/*
  Left
  ----------------------------------------
*/

.gen-side.left {
  transform-origin: 100% 50%;
  transform: rotateY($panel-angle);
  .gen-top-panel {
    padding-left: 0;
    .empty-panel:first-child {
      border-left: none;
      &::before {
        border-left: none;
        left: 0;
        background: $color-bg;
      }
    }
  }
  .gen-panel {
    border-left: none;
    padding-left: 0;
    &::before {
      border-left: none;
      left: 0;
      background: $color-bg;
    }
  }
}

/*
  Right
  ----------------------------------------
*/

.gen-side.right {
  transform-origin: 0% 50%;
  transform: rotateY(-$panel-angle);
  .gen-top-panel {
    padding-right: 0;
    .empty-panel:last-child {
      border-right: none;
      &::before {
        border-right: none;
        right: 0;
        background: $color-bg;
      }
    }
  }
  .gen-panel {
    border-right: none;
    padding-right: 0;
    &::before {
      border-right: none;
      right: 0;
      background: $color-bg;
    }
  }
  .space-screen {
    display: none;
  }
  @include media(3-up) {
    .space-screen {
      display: flex;
    }
  }
}

/*
  Buttons
  ----------------------------------------
*/

.gen-controls {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 9px;
  grid-gap: 8px;
  position: relative;
  button {
    --border-w: 6px;
    --depth: 4px;
    @include extrude($color-green, false, 2px);
    color: $color-panel-inner;
    font-weight: 600;
    border: none;
    flex: 1;
    min-height: var(--button-h);
    position: relative;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(to bottom, rgba($color-white, 0.1), rgba($color-black, 0.15));
      z-index: 0;
      pointer-events: none;
    }
  }
  button.connect {
    $color-connect: lighten($color-green, 36%);
    @include extrude($color-connect, false, 2px, false);
    color: darken($color-connect, 60%);
    text-shadow:
      0 -1px 0 darken($color-connect, 5%),
      0 1px 0 lighten($color-connect, 5%);
  }
  button.generate {
    $color-generate: darken($color-blue, 16%);
    @include extrude($color-generate, false, 2px, false);
    color: darken($color-generate, 45%);
    text-shadow:
      0 -1px 0 darken($color-generate, 5%),
      0 1px 0 lighten($color-generate, 5%);
  }
  button.mint {
    $color-mint: saturate(lighten($color-red, 10%), 12%);
    @include extrude($color-mint, false, 2px, false);
    color: darken($color-mint, 45%);
    text-shadow:
      0 -1px 0 darken($color-mint, 5%),
      0 1px 0 lighten($color-mint, 5%);
  }
  button[disabled] {
    $color-disabled: desaturate(lighten($color-panel-inner, 15%), 10%);
    @include extrude($color-disabled, false, 2px, false);
    color: darken($color-disabled, 30%);
    text-shadow:
      0 -1px 0 darken($color-disabled, 5%),
      0 1px 0 lighten($color-disabled, 5%);
  }

  button:not([disabled]):hover {
    filter: brightness(1.16);
  }
}

.mint-price {
  width: 100%;
  position: relative;
  // background-color: #cab39b;
  &::before {
    content: '';
    width: 100%;
    top: 0; right: 0; bottom: 0; left: 0;
    position: absolute;
    border-width: 8px;
    border-style: solid;
    border-top-color: #a58c72;
    border-left-color: #a58c72;
    border-right-color: #dccdbc;
    border-bottom-color: #dccdbc;
  }
  &::after {
    
  }

  p {
    color: $color-white;
    font-size: 13px;
    text-align: center;
    margin: 0;
    padding: 16px 0;
  }
}

.panel-floor {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200%;
    border-left: 1px solid rgba($color-black, 0.15);
    border-right: 1px solid rgba($color-black, 0.15);
    background: linear-gradient(0deg, darken($color-panel-bg, 15%), $color-panel-bg);
  }
}

/*
  Dogs
  ----------------------------------------
*/

.gen-dog-control-group {
  position: relative;
}

.gen-control-station,
.gen-dogs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  img {
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
    object-fit: cover;
    object-position: bottom center;
  }
}

.gen-dogs {
  position: absolute;
}

.gen-control-station {
  position: relative;
}

.gen-dogs img {
  max-width: 70%;
  position: relative;
  left: 2%;
}

/*
  Screen UI
  ----------------------------------------
*/

.gen-side .gen-panel .screen-ui {
  grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
  grid-gap: 8px;
  grid-auto-rows: minmax(24px, auto);
  grid-auto-flow: dense;
  overflow: hidden;
  grid-column: span 2;
  position: relative;
  z-index: 10;
  display: none;
  @include media(3-up) {
    display: grid;
  }
  .ui-pill {
    position: relative;
    border-radius: 4px;
    border: 1px solid darken($color-bg, 10%);
    color: $color-red;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 5px;
    user-select: none;
  }
  .text {
    position: absolute;
    text-align: right;
    top: 2px;
    right: 3px;
    height: auto;
  }
  .num {
    position: absolute;
    bottom: 2px;
    left: 3px;
  }
  .bg {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 1px;
    background: $color-green;
    animation: fade-ui linear infinite;
  }

  .ui-pill:nth-of-type(odd) .bg {
    background: $color-blue;
  }
  .ui-pill:nth-of-type(even) .bg {
    background: $color-green;
  }
}

@keyframes fade-ui {
  0% {
    opacity: 0;
  }
  @for $i from 1 through 9 {
    #{($i * 10) + "%"} {
      opacity: math.div(random(30), 100);
    }
  }
  100% {
    opacity: 0;
  }
}

.scan-enter-active,
.scan-leave-active {
  transition: all 0.15s;
}

.scan-enter,
.scan-leave-to {
  opacity: 1;
}

.scan-enter,
.scan-leave-to {
  opacity: 0;
}

.loader2{
	position: absolute;
	width: 5px;
	height: 5px;
	top: calc(50% - 2.5px);
	left: calc(50% - 2.5px);
	background-color: $color-red;
	border-radius: 50px;
}

.loader2:before{
	content: "";
	position: absolute;
	top: -38px;
	border-top: 3px solid $color-red;
	border-right: 3px solid $color-red;
	border-radius: 0 50px 0px  0;
	width: 40px;
	height: 40px;
	background-color: rgba(255, 255, 255, .1);
	-webkit-transform-origin:  0% 100%;
			transform-origin:  0% 100% ;
	-webkit-animation: loader2 1.5s linear infinite;
			animation: loader2 1.5s linear infinite;
}

.loader2:after{
	content: "";
	position: absolute;
	top: 2px;
	right: 2px;
	border-bottom: 3px solid $color-red;
	border-left: 3px solid $color-red;
	border-radius: 0 0px 0px  50px;
	width: 40px;
	height: 40px;
	background-color: rgba(255, 255, 255, .1);
	-webkit-transform-origin:  100% 0%;
			transform-origin:  100% 0% ;
	-webkit-animation: loader2 1.5s linear infinite;
			animation: loader2 1.5s linear infinite;
}


@-webkit-keyframes loader2{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(360deg);}
}

@keyframes loader2{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
}


</style>
