<template>
  <section class="sec-mission" id='sec-mission'>
    <header>
      <h2
        class="subtitle"
        data-subtitle='Mission'
      >
        Welcome to Muttniks Mission Control
      </h2>
      <p>
        Muttnik NFTs celebrate the legendary cosmic canine friends who paved the way for human space flight.
      </p>
    </header>

    <div class="timeline">
      <div class="timeline--event">
        <div class="event--year">1951</div>
        <div class="event-spine"/>
        <div class="event--desc">
          <p>
            In 1951, the Soviet Union was the First Nation in the world to
            send the first dogs into space.
          </p>
          <figure class="event--image image">
            <img src="@/assets/image/img-1951.png" alt="" />
            <figcaption class="event--image-caption">
              Dezik and Tsygan
            </figcaption>
          </figure>
        </div>
      </div>

      <div class="timeline--event">
        <div class="event--year">July 22, 1951</div>
        <div class="event-spine"/>
        <div class="event--desc">
          <p>
            July 22, 2021 marked the 70th Anniversary of the of the first flight of Lezik and Tsyga,
            Cosmic Paws honors our Earth famous space dogs–starting with the most famously-known space
            dog of the Muttniks collection: Laika. (SOLD OUT) We now share with the world Zyezdochka (aka STAR)
          </p>
          <figure class="event--image image">
            <img src="@/assets/image/img-july-1951.png" alt="" />
            <figcaption class="event--image-caption">
              Dezik and Lisa
            </figcaption>
          </figure>
        </div>
      </div>

      <div class="timeline--event">
        <div class="event--year">1957</div>
        <div class="event-spine"/>
        <div class="event--desc">
          <p>
            Jump foward to 1957, when Laika (nicknamed a “Muttnik” in
            reference to her being mixed-breed and following the
            successful flight of first spacecraft Sputnik) became the
            first living being to orbit the earth in a spacecraft. It is
            now the stuff of legend how no recourse was made for Laika’s
            safe return; she famously died orbiting the earth.
          </p>
          <figure class="event--image image">
            <img src="@/assets/image/img-1957.png" alt="" />
            <figcaption class="event--image-caption">
              Laika (aka Muttnik)
            </figcaption>
          </figure>
          <p>
            Though these fur friends unwittingly went to space, they
            ultimately became pioneers. Earth’s first dogs in space are
            all a special kind of space dog hero. Each and every one is
            worthy of being remembered and celebrated. Like all good dogs,
            Muttniks live for fun. We have created the collection Muttniks 
            the kind of adventurers life and dog family love all of them
            deserve. Remixes will extend their lives even fur-ther!
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'sec-mission'
};
</script>

<style lang='scss'>
@import '../styles/vars';
@import '../styles/mixins';

.sec-mission {
  max-width: var(--max-width-l);
  padding: var(--v-space) var(--gutter) 0;
  position: relative;
  margin: auto;
  .subtitle::before {
    @include base-font(h1);
  }
}

.sec-mission header {
  margin-bottom: var(--v-space);
  p {
    max-width: var(--max-width-m);
  }
}

/*
 * Timeline
 */
.sec-mission .timeline {
  --col-year: 200px;
  --col-spine-w: 92px;
  --col-content: minmax(300px, 632px);
  --dot-size: 36px;
  margin-top: 60px;
}

.sec-mission .timeline--event {

  --col-year: 40px;
  --col-spine-w: 30px;
  --dot-size: 24px;
  --col-content: minmax(100px, var(--max-width-m));
  
  display: grid;
  grid-template-columns: var(--col-year) var(--col-spine-w) var(--col-content);
  grid-gap: 16px;

  @include media(3-up) {
    --col-year: 140px;
    --col-spine-w: 45px;
  }

  // Year
  .event--year {
    color: $color-red;
    font-family: $font-computer;
    @include base-font(h4);
    position: relative;
    text-align: left;
  }

  // Spine
  .event-spine {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;

    // Line
    &::before {
      content: '';
      position: absolute;
      display: flex;
      align-content: center;
      justify-content: center;
      width: 1px;
      height: 100%;
      background-color: $color-green6;
    }

    // Dot
    &::after {
      top: calc(var(--dot-size) * -0.15);
      content: '';
      position: absolute;
      width: var(--dot-size);
      height: var(--dot-size);
      border-radius: var(--dot-size);
      background: $color-red;
      box-shadow: inset 0 0 0 0.4em $color-green6, inset 0 0 0 0.6em $color-green;
    }
  }

  // Desc
  &:last-child .event--desc {
    padding-bottom: 24px;
  }

  // Images
  figure.event--image {
    margin: 0 0 24px;
    position: relative;
    width: 220px;
    @include media(3-up) {
      width: 420px;
    }
    img {
      object-fit: cover;
      filter: hue-rotate(45deg);
    }
  }
  figcaption.event--image-caption {
    color: $color-green6;
    font-family: "Computer", sans-serif;
    font-size: 16px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    padding: 11px 20px;
    bottom: -1px;
    left: -1px;
    position: absolute;
    background-color: $color-bg;
    display: none;
    @include media(3-up) {
      display: inline-block;
    }
  }
}
</style>