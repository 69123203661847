<template>
  <section class="sec-roadmap"  id='sec-roadmap'>
    <h2
      class='subtitle'
      data-subtitle="Road-Map"
    >
      Like every great mission, Muttniks have a Road-Map
    </h2>
    <div class="timeline">
      <div class="targets">
        <span
          class="target"
          @click='onTargetClick'
          v-for='item in milestones'
          :key='item.milestone'
          v-html='`${item.target}%`'
          :class="{ active: item.target === activeTarget }"
          :data-target='item.target'
        />
      </div>
      <div class="time">
        <hr class="time-all"/>
        <!-- <hr
          class="time-current"
          :style='{ transform: `scaleX(${activeTarget/100})`}'
        /> -->
      </div>
      <transition-group
        name='fade'
        class="milestones"
        :style="milestonesStyle"
        tag='div'
      >
        <p
          v-for='item in milestones'
          :key='item.target'
          :class="{ active: item.target === activeTarget }"
          v-html='item.desc'
          class="milestone"
        />
      </transition-group>
    </div>
    <p class="road-message">Traits will be removed from the generator periodically
      throughout the time in which Star is available to mint to promote rarity.
      Many more exciting announcements coming for
      2021 including a private in person event in
      Los Angeles for Muttnik holders. <em>(Your Muttnik IS your ticket)</em></p>
  </section>
</template>

<script>
export default {
  name: 'sec-roadmap',
  data() {
    return {
      activeTarget: 25,
      milestones: [
        {
          target: 25,
          desc: 'Exclusive giveaway of 10 Star Muttniks.'
        },
        {
          target: 50,
          desc: 'Merch Rollout first offered to Muttnik Holders before public sale on <a href="https://goingtospace.com">goingtospace.com</a>'
        },
        {
          target: 75,
          desc: 'Begin 16 ETH community fund to start Muttnik Defi Project.'
        },
        {
          target: 100,
          desc: '$25,000.00 Donated to a Canine Charity TBA!'
        }
      ]
    };
  },
  computed: {
    currentIndex() {
      return this.milestones.findIndex(item => item.target === this.activeTarget);
    },
    milestonesStyle() {
      return {
        width: `${this.milestones.length * 100}vw`,
        transform: `translate3d(${this.currentIndex * -100}vw, 0, 0)`
      };
    }
  },
  methods: {
    onTargetClick(event) {
      const { target } = event.target.dataset;
      if (target) {
        this.activeTarget = Number(target);
      }
    }
  }
};
</script>

<style lang='scss' scoped>

@import '../styles/vars';
@import '../styles/mixins';

.sec-roadmap {
  --size: 100vw;
  --border-w: 8px;
  --depth: 24px;
  --gutter2: calc(var(--gutter) * 2);

  @include extrude(desaturate(darken($color-beige, 10%), 10%), 1px, 5px, false);
  color: $color-green;
  position: relative;
  text-align: center;
  padding: var(--v-space) 0 calc(var(--v-space) * 1);
  .subtitle {
    text-align: left;
    margin: 0;
    color: $color-green;
    padding: 0 var(--gutter2);
    @include media(3-up) {
      text-align: center;
      &::after {
        align-self: center;
      }
    }
  }
  .timeline {
    padding: var(--v-space) 0 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .targets {
    @include base-font(h3);
    font-family: $font-computer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: var(--max-width-l);
    padding: 0 var(--gutter2);
  }
  .target {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: -40px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      transition: all 0.3s ease-in-out;
    }
    &::before {
      background: $color-green;
    }
  }
  .target:hover {
    color: $color-red;
  }
  .target.active {
    color: $color-red;
    &::before {
      background: $color-red;
    }
    &::after {
      border: 1px solid $color-red;
      transform: scale(2);
    }
  }
  .time {
    position: relative;
    display: block;
    margin: 32px 0 var(--v-space);
    hr {
      margin: 0;
      border: none;
      height: 1px;
      display: block;
    }
    hr.time-all {
      background: $color-green;
      opacity: 1;
      position: absolute;
      left: 24px;
      top: 0;
      right: 24px;
    }
    hr.time-current {
      width: 100%;
      margin: auto;
      width: 100%;
      max-width: var(--max-width-l);
      position: relative;
      background: $color-red;
      transform-origin: 0 50%;
      transition: transform 0.3s ease-in-out;
    }
  }
  .milestones {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transition: transform 0.3s ease-in-out;
  }
  .milestone {
    text-align: center;
    @include base-font(h3);
    margin: 0;
    padding: 0 var(--gutter2);
    width: 100vw;
  }
  .road-message {
    max-width: var(--max-width-m);
    margin: auto;
    padding: var(--v-space) calc(var(--gutter) * 2) var(--v-space);
  }
}
</style>